import React, { useEffect } from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery, larger } from '@vega/styled'
import { useSelector, useDispatch } from 'react-redux'
import { routes } from 'routes'
import { UpcomingPaymentsContainer } from './UpcomingPaymentsContainer'
import LoanInfoCard from './loanInfoCard'
import { ReactComponent as ChevronLeftBase } from '@vega/components/src/assets/images/chevron-left.svg'
import PaymentHeader from './PaymentHeader'
import { PaymentProgressBar } from './PaymentProgressBar'
import {
  selectIsFetchCustomerLoansPending,
  selectIsFetchCustomerPaymentsPending,
} from 'modules/customer'
import { fetchLoan, selectLoanDetails } from 'modules/mortgageAccounts'
import { fetchPayments, selectAllPayment } from 'modules/payment'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { useHistory, useParams } from 'react-router-dom'

const Root = styled.div(
  s('flex flex-column flex-1 pt-8 ', {
    overflow: 'hidden',
  })
)
const ChevronLeft = styled(ChevronLeftBase)(
  s('my-5 text-grey-700', { cursor: 'pointer' })
)
const Scrollable = styled.div(
  s('flex flex-column flex-1 w-full rounded-b-lg ', {
    overflowY: 'auto',
    overflowX: 'hidden',
  })
)
const PaymentCenterTitle = styled.div(
  s('text-2xl text-primary font-semibold'),
  {
    whiteSpace: 'nowrap',
    letterSpacing: '-0.02em',
  },
  larger('lg')(s('pb-6', { fontSize: 30, marginLeft: -17, marginTop: -8 }))
)

const SummaryTitle = styled.div(
  s('text-3xl text-primary font-semibold'),
  { cursor: 'pointer' },
  larger('lg')({ fontSize: 20 })
)

const HeaderContainer = styled.div(
  s('flex flex-column px-4 py-8 w-full mx-a mb-8'),
  larger('lg')(s('px-8')),
  ({ isLargeScreen }) => isLargeScreen && s('mb-10')
)

const PaymentContainer = styled.div(
  s('relative flex flex-column justify-start items-center w-full'),
  ({ hidden }) => hidden && s('hidden')
)

const PaymentInfoContainer = styled.div(
  s('absolute flex flex-column justify-start items-center w-full mt-24 h-5')
)

const ButtonContainer = styled.div(
  s('flex flex-row w-full mx-a '),
  ({ hidden }) => hidden && s('hidden')
)

const SummaryContainer = styled.div(s('w-full'), ({ hidden }) => hidden && s('hidden'))
const Space = styled.div(s('bg-white flex my-8 justify-between text-base'))
const CardContainer = styled.div(
  s('bg-white rounded-b-lg w-full ', {
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
  })
)

// eslint-disable-next-line complexity
function LoanPaymentDetails() {
  const ifHasNoMorePayments = (payments) => {
    if (
      !isNilOrEmpty(payments) &&
      (typeof payments === 'object' || payments.length < 6)
    )
      return true
    return false
  }

  const sortByDate = (a, b) => {
    if (a.dueDate > b.dueDate) {
      return 1
    }
    if (a.dueDate < b.dueDate) {
      return -1
    }
    return 0
  }

  const getMorePayments = (payments) => {
    if (isNilOrEmpty(payments)) return payments
    if (typeof payments === 'object' || payments.length < 6) return payments
    return payments.slice(6, 12)
  }
  const isLargeScreen = useLargeMediaQuery('larger')
  const dispatch = useDispatch()
  const history = useHistory()
  const { id: loanId } = useParams()

  useEffect(() => {
    dispatch(fetchLoan(loanId))
    dispatch(
      fetchPayments({
        searchParams:{
        filters: {
          loanId,
          status: 'pending',
        },
      }
      })
    )
  }, [dispatch, loanId])

  const loan = useSelector(selectLoanDetails)
  const payments = useSelector(selectAllPayment)
  const paymentsValue = Object.values(payments)
  const paymentsArray = paymentsValue.sort(sortByDate)
  const hasNoMorePayments = ifHasNoMorePayments(paymentsArray)
  const isFetchingLoans = useSelector(selectIsFetchCustomerLoansPending)
  const isFetchingPayments = useSelector(selectIsFetchCustomerPaymentsPending)

  const isLoading = isFetchingPayments || isFetchingLoans
  const isLargerThanLargeScreen = useLargeMediaQuery('larger')
  const goToPaymentCentre = () =>
    history.push(`${routes.customer.paymentSchedule.root}`)

  return (
    <Root>
      <Scrollable>
        {!isLargeScreen && (
          <Row>
            <Col span={24} lg={0}>
              <ButtonContainer
                onClick={goToPaymentCentre}
                style={{ marginTop: -25, marginLeft: 10 }}
              >
                <ChevronLeft />{' '}
                <SummaryTitle style={(s('ml-2'), { marginTop: 20, fontSize: 16 })}>
                  Back
                </SummaryTitle>
              </ButtonContainer>
            </Col>
          </Row>
        )}
        <HeaderContainer>
          <Row justify="center">
            <Col span={24} lg={16}>
              <PaymentCenterTitle className="sohne" isLarge={isLargerThanLargeScreen}>
                Loan Payment Schedule Details
              </PaymentCenterTitle>
              {isLargeScreen && (
                <div style={(s('text-primary'), { marginLeft: -16, marginTop: -20 })}>
                  {' '}
                  {loan?.customLoanName || loan?.product?.displayName}
                </div>
              )}
              {!isLargeScreen && (
                <div style={s('text-primary')}>
                  {' '}
                  {loan?.customLoanName || loan?.product?.displayName}
                </div>
              )}
            </Col>
            <Col span={0} lg={5} />
          </Row>
        </HeaderContainer>

        {!isLargeScreen && (
          <Row>
            <Col span={24} lg={0}>
              <PaymentContainer style={s('p-4')}>
                <PaymentInfoContainer style={s('px-4')}>
                  <PaymentProgressBar
                    loanBalance={loan?.balance}
                    loanAmount={loan?.loanAmount}
                  />
                </PaymentInfoContainer>
                <PaymentHeader loanLimit={loan?.loanLimit} />
                <Row />

                <UpcomingPaymentsContainer
                  loans={loan}
                  payments={paymentsArray}
                  isLoading={isLoading}
                  getMorePayments={getMorePayments(payments)}
                  hasNoMorePayments={hasNoMorePayments}
                />
              </PaymentContainer>
              <SummaryContainer style={s('p-4')}>
                <SummaryTitle className="sohne"> Summary</SummaryTitle>
                <LoanInfoCard
                  interestRate={loan?.product?.initialInterestRate}
                  initialLoanType={loan?.product?.initialRepaymentType}
                  initialTerm={loan?.loanTermInMonths}
                  ongoingLoanType={loan?.product?.ongoingRepaymentType}
                  ongoingTerm={loan?.loanTermInMonths}
                />
              </SummaryContainer>
            </Col>
          </Row>
        )}

        <Row justify="start" style={s('ml-24')}>
          <Col span={24} lg={6}>
            <SummaryContainer hidden={!isLargeScreen}>
              <SummaryTitle className="sohne"> Summary</SummaryTitle>
              <LoanInfoCard
                interestRate={loan?.product?.initialInterestRate}
                initialLoanType={loan?.product?.initialRepaymentType}
                initialTerm={loan?.loanTermInMonths}
                ongoingLoanType={loan?.product?.ongoingRepaymentType}
                ongoingTerm={loan?.loanTermInMonths}
              />
            </SummaryContainer>
            <ButtonContainer onClick={goToPaymentCentre} hidden={!isLargeScreen}>
              <ChevronLeft />{' '}
              <SummaryTitle style={(s('ml-2'), { marginTop: 21, fontSize: 16 })}>
                Back
              </SummaryTitle>
            </ButtonContainer>
          </Col>
          <Col span={24} lg={14}>
            <PaymentContainer hidden={!isLargeScreen}>
              <PaymentInfoContainer>
                <PaymentProgressBar
                  loanBalance={loan?.balance}
                  loanAmount={loan?.loanAmount}
                />
              </PaymentInfoContainer>
              <PaymentHeader loanLimit={loan?.loanLimit} />

              <CardContainer>
                <UpcomingPaymentsContainer
                  loans={loan}
                  payments={paymentsArray}
                  isLoading={isLoading}
                  getMorePayments={getMorePayments(paymentsArray)}
                  hasNoMorePayments={hasNoMorePayments}
                />
              </CardContainer>
            </PaymentContainer>
          </Col>
        </Row>
        <Space />
      </Scrollable>
    </Root>
  )
}

export default LoanPaymentDetails
