import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { reconciledPaymentsService } from 'apiService'
import { normalizeError } from '@vega/services'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { createCustomError } from '@vega/error-standardizer'

export const fetchReconciledPayments = createAsyncThunk(
  'reconciledPayments/fetchReconciledPayments',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, sorting = {}, limit = 20 } =
        searchParams || {}
      return await reconciledPaymentsService.fetchReconciledPayments(
        {
          q,
          filters,
          start: limit * pageIndex,
          sorting,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const reconciledPaymentsUpdateNote = createAsyncThunk(
  'reconciledPayments/updateNote',
  async ({ id, note, searchParams }, { dispatch, rejectWithValue }) => {
    try {
      if (isNilOrEmpty(note))
        throw createCustomError({
          type: 'reconciledPaymentsNoteUpdateFailed',
          description: 'Reconciled Payments Note Update Failed',
        })

      await reconciledPaymentsService.updateNote(id, note)

      return dispatch(fetchReconciledPayments({ searchParams, pageIndex: 0 }))
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const reconciledPaymentsAdapter = createEntityAdapter({
  selectId: (payment) => payment._id,
})

const initialState = reconciledPaymentsAdapter.getInitialState()

const reconciledPaymentsSlice = createSlice({
  name: 'reconciledPayments',
  initialState,
  extraReducers: {
    [fetchReconciledPayments.fulfilled]: (state, { payload }) => {
      const { items: reconciledPayments, pagination } = payload

      reconciledPaymentsAdapter.setAll(state, reconciledPayments)
      state.total = pagination.total
    },
  },
})

const { reducer: reconciledPaymentsReducer } = reconciledPaymentsSlice

export { reconciledPaymentsReducer }
