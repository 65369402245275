import React from 'react'
import { noop } from '@neo/ramda-extra'
import { useHistory } from 'react-router-dom'

import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { SectionTitle as SectionTitleBase } from '@vega/components/src/v2'
import { ReactComponent as ChevronLeft } from '@vega/components/src/assets/images/chevron-left.svg'

import { routes } from 'routes'

const CustomerName = styled(SectionTitleBase)(s('text-3xl'))
const BackButton = styled.button(
  s('border-transparent bg-transparent p-0 flex flex-row')
)
const BackButtonText = styled(SectionTitleBase)(s('mt-1 text-sm'))
const SendInvitationButton = styled.button(
  s('border-transparent px-4 py-2 font-semibold text-primary bg-secondary'),
  {
    cursor: 'pointer',
    fontSize: 14,
    borderRadius: 6,
  }
)

export const Header = ({
  customerName,
  shouldDisplayInvitationBtn = false,
  shouldDisplayReInviteBtn = false,
  openModal = noop,
}) => {
  const history = useHistory()
  return (
    <Row justify="center" style={s('my-8 ml-8')}>
      <Col span={20}>
        <Row style={s('mb-10')}>
          <BackButton onClick={() => history.push(routes.admin.customers.overview)}>
            <ChevronLeft /> <BackButtonText title="Back" />
          </BackButton>
        </Row>

        <Row style={s('justify-between items-center')}>
          <CustomerName title={`${customerName}'s Details`} />

          {shouldDisplayInvitationBtn && (
            <SendInvitationButton onClick={openModal}>
              Send Access Invitation
            </SendInvitationButton>
          )}
          {shouldDisplayReInviteBtn && (
            <SendInvitationButton onClick={openModal}>
              Resend Access Invitation
            </SendInvitationButton>
          )}
        </Row>
      </Col>
    </Row>
  )
}
