import React from 'react'
import * as Validator from 'yup'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { styled, s } from '@vega/styled/v2'
import { ReactComponent as CloseButtonBase } from '@vega/components/src/assets/images/x.svg'
import {
  Button as ButtonBase,
  Modal,
  DateField as DateFieldBase,
  SelectField as SelectFieldBase,
} from '@vega/components'
import { PAYMENT_CONSTANTS } from '@vega/constants'
import { fetchPaymentsExport } from 'modules/payment'
import { isNotNilOrEmpty } from '@neo/ramda-extra'

const { PAYMENT_STATUS, PAYMENT_METHOD } = PAYMENT_CONSTANTS

const PAYMENT_STATUS_DISPLAY_VALUE = {
  [PAYMENT_STATUS.NOT_DUE_YET]: 'Not due yet',
  [PAYMENT_STATUS.DUE_TODAY]: 'Due today',
  [PAYMENT_STATUS.OVERDUE_LESS_THAN_30_DAYS]: 'Overdue < 30 days',
  [PAYMENT_STATUS.OVERDUE_LESS_THAN_60_DAYS]: 'Overdue 31 - 60 days',
  [PAYMENT_STATUS.OVERDUE_GREATER_THAN_60_DAYS]: 'Overdue > 60 days',
}

const PAYMENT_METHOD_DISPLAY_VALUE = {
  [PAYMENT_METHOD.DIRECT_DEBIT]: 'Direct debit',
}

const Container = styled.div(
  s('px-9 py-9 flex flex-column bg-white', { borderRadius: 12, maxHeight: '80vh' })
)
const FormContainer = styled.div(
  s('flex-1', {
    msOverflowStyle: 'none',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  })
)
const Header = styled.div(s('flex justify-between'))
const Title = styled.div(
  s('text-xl text-grey-800 font-semibold', { letterSpacing: '0.02em' })
)
const Notify = styled.div(
  s('text-sm text-grey-800 font-normal', {
    letterSpacing: '0.02em',
    lineHeight: 4.2,
    marginTop: -4,
  })
)
const Description = styled.div(
  s('text-sm font-normal text-grey-600 mb-5', { marginTop: -16 })
)
const CloseButton = styled(CloseButtonBase)(s('text-grey-700', { cursor: 'pointer' }))
const DateField = styled((props) => (
  <DateFieldBase containerProps={{ style: s('flex flex-column flex-1') }} {...props} />
))()
const SelectField = styled(SelectFieldBase)(s('mb-5', { minWidth: 465 }))
const ButtonContainer = styled.div(s('flex'))
const Button = styled(ButtonBase)(s('px-3 py-4 text-grey-900'), { minWidth: 92 })
const { object, string } = Validator

const validationSchema = object({
  paymentDueStatus: string().when('dueDate', {
    is: (dueDate) => isNotNilOrEmpty(dueDate),
    then: string().max(0, 'Please only select one filter at the time'),
  }),
  paymentMethod: string().when('dueDate', {
    is: (dueDate) => isNotNilOrEmpty(dueDate),
    then: string().max(0, 'Please only select one filter at the time'),
  }),
  dueDate: string().notRequired(),
})

const BulkExport = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch()
  const onClose = () => setIsOpen(false)
  const exportPayments = (values) => {
    dispatch(
      fetchPaymentsExport({
        filters: {
          dueDate: values.dueDate,
          paymentDueStatus: values.paymentDueStatus,
        },
      })
    )
    setIsOpen(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <Header>
          <Title>Export reports</Title>
          <CloseButton onClick={onClose} />
        </Header>
        <Notify>
          Please select one filter per report. We are working on complexed filters in
          the future.
        </Notify>
        <FormContainer>
          <Formik
            initialValues={{
              paymentDueStatus: '',
              paymentMethod: '',
              dueDate: '',
            }}
            validationSchema={validationSchema}
            onSubmit={exportPayments}
          >
            {({ isSubmitting }) => (
              <Form>
                <SelectField
                  name="paymentDueStatus"
                  label="Status"
                  placeholder="Select payment status"
                  options={Object.entries(PAYMENT_STATUS).map(([, value]) => ({
                    label: PAYMENT_STATUS_DISPLAY_VALUE[value],
                    value,
                  }))}
                />

                <Description>All reports will be exported as .CSV file</Description>
                <SelectField
                  name="paymentMethod"
                  label="Payment method"
                  placeholder={'Select payment method'}
                  options={Object.entries(PAYMENT_METHOD).map(([, value]) => ({
                    label: PAYMENT_METHOD_DISPLAY_VALUE[value],
                    value,
                  }))}
                />
                <DateField
                  name="dueDate"
                  label="Selected due date"
                  placeholder="YYYY-MM-DD"
                  inputFormat="yyyy-MM-dd"
                />
                <ButtonContainer>
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    style={s('mr-4 mt-8 rounded-lg')}
                    download
                  >
                    Export
                  </Button>
                  <Button
                    variant="outlined"
                    style={s('border-grey-300 mt-8 rounded-lg')}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </Modal>
  )
}

export { BulkExport }
