/* eslint-disable complexity */
import { isNilOrEmpty } from '@neo/ramda-extra'

export const findCurrentMonth = (array, month, year) => {
  let num = 0

  if (!Array.isArray(array) || isNilOrEmpty(array)) {
    return 0
  }

  while (array[num].month !== month || array[num].year !== year) {
    num += 1

    if (num === array.length) {
      num = 0.5
      break
    }
  }

  return num
}
