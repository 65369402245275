/* eslint-disable complexity */
import React from 'react'
import { useLargeMediaQuery } from '@vega/styled'
import { DateBox as DueDateBox } from '@vega/components/src/v2'
import { s, styled } from '@vega/styled/v2'
import { PAYMENT_STATUS } from '@vega/constants'
import { formatCurrency } from 'utils/formatter'
import { isAfter } from 'date-fns'

const StatusPill = styled.div(
  s('rounded-2xl h-2 flex items-center justify-center text-xs font-semibold'),
  ({ status }) =>
    status === PAYMENT_STATUS.ONSCHEDULE ? s('bg-accent-pink') : s('bg-accent-red text-white')
)
const WarningContainer = styled.div(
  s('text-accent-red font-normal'),
  ({ hidden }) => hidden && s('hidden')
)

function PaymentCard({ loan, payment }) {
  let status = ''
  if (isAfter(new Date(), new Date(payment?.dueDate))) {
    status = PAYMENT_STATUS.OVERDUE
  } else {
    status = PAYMENT_STATUS.ONSCHEDULE
  }
  const { customLoanName } = loan || {}
  const { displayName } = loan?.product || {}
  const { dueDate, amount } = payment || {}
  const isLargeScreen = useLargeMediaQuery('larger')

  return (
    <div style={s('flex my-2')}>
      <DueDateBox date={new Date(dueDate)} />

      <div style={s('text-black font-semibold text-lg ml-2', {flexGrow: 1})}>
        {customLoanName || displayName}
        {
          isLargeScreen ? (
            <div style={s('flex')}>
              <StatusPill status={status} style={s('px-2 mr-2', {width: 'fit-content'})}>
                {status.toUpperCase()}
              </StatusPill>

              <WarningContainer hidden={!isAfter(new Date(), new Date(dueDate))}>
                Contact Neo Admin to resolve this payment
              </WarningContainer>
            </div>
          ) : (
            <>
              <StatusPill status={status} style={s('px-2', {width: 'fit-content'})}>
                {status.toUpperCase()}
              </StatusPill>

              <WarningContainer style={s('text-sm')} hidden={!isAfter(new Date(), new Date(dueDate))}>
                Contact Neo Admin to resolve this payment
              </WarningContainer>
            </>
          )
        }
      </div>

      <div style={s('text-right ml-1 font-semibold')}>
        {formatCurrency(amount, 2)}
      </div>
    </div>
  )
}

export default PaymentCard
