import React from 'react'
import { Row, Col, Container, larger } from '@vega/styled'
import { s, styled } from '@vega/styled/v2'
import { formatCurrency } from 'utils/formatter'
import { REPAYMENT_TYPES, LOAN_GROUP_STATUS } from '@vega/constants'

const { INTEREST_ONLY, PRINCIPAL_AND_INTEREST } = REPAYMENT_TYPES

const OuterContainer = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
    }
  )
)
const Root = styled.div(
  s(
    'bg-white flex flex-row justify-between w-full border-0 border-b-1 border-solid border-grey-200 mt-4 mb-4 ',
    {
      '&:last-child': {
        borderColor: 'transparent',
      },
      ':hover': s('bg-secondary rounded-lg', {cursor: 'pointer'}),
    }
  )
)

const MortgageName = styled.div(s('text-primary font-semibold text-base mt-1'))

const MortgageAccountNum = styled.div(s('flex flex-row'))

const MortgageLoanDataBox = styled.div(s('flex flex-column mt-6 justify-end'))

const SubTitle = styled.div(
  s('text-sm text-grey-600  mb-1 text-left'),
  larger('xl')(s('text-right'))
)

const Value = styled.div(
  s('text-base text-right text-grey-800 font-semibold text-left'),
  larger('xl')(s('text-right'))
)

const ExtraLoanInfo = styled.div(s('text-base text-primary'))

const repaymentTypeLabel = (repaymentType) => {
  switch(repaymentType) {
    case INTEREST_ONLY: return 'Interest only';
    case PRINCIPAL_AND_INTEREST: return 'Principal and interest';
    default: return '';
  }
}

const loanBalanceLabel = (loanGroupStatus, loanBalance) =>
  loanGroupStatus === LOAN_GROUP_STATUS.APPROVED ? formatCurrency(loanBalance, 2) : '-'

const MortgageCard = ({
  name = '-',
  accountNumber = '-',
  loanBalance = '-',
  loanDuration = '-',
  repaymentType = '-',
  loanGroupStatus = '-',
  ...props
}) => {
  return (
    <OuterContainer>
      <Root {...props}>
        <Container gutter={0}>
          <Row gutter={[0, 0]} style={s('p-2')}>
            <Col span={12}>
              <MortgageAccountNum>
                <div style={s('text-grey-600 text-sm mb-1')}>
                  Loan No. {accountNumber}
                </div>
              </MortgageAccountNum>
              <MortgageName className="sohne">{name}</MortgageName>
              <ExtraLoanInfo>- {loanDuration} years</ExtraLoanInfo>
              <ExtraLoanInfo>- {repaymentTypeLabel(repaymentType)}</ExtraLoanInfo>
            </Col>

            <Col span={12}>
              <MortgageLoanDataBox style={s('justify-end')}>
                <SubTitle style={s('text-right')}>Loan Balance</SubTitle>

                <Value style={s('text-right')}>
                  {loanBalanceLabel(loanGroupStatus, loanBalance)}
                </Value>
              </MortgageLoanDataBox>
            </Col>
          </Row>
        </Container>
      </Root>
    </OuterContainer>
  )
}

export default MortgageCard
