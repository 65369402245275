import React from 'react'
import { Container, Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '@vega/components/src/v2'
import { DottedCircleLoader } from '@vega/components'
import { isNilOrEmpty, isNotNilOrEmpty } from '@neo/ramda-extra'
import { INVITATION_STATUSES, LEGAL_ENTITY } from '@vega/constants'
import formatters from 'utils/formatters'
import { formatDateString } from 'utils/date/formatDateString'

const {
  TYPE: { COMPANY, TRUST },
} = LEGAL_ENTITY

const Label = styled.div(
  s('text-sm font-normal', {
    color: '#515856',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Value = styled.div(
  s('text-base font-semibold', {
    color: '#282C2B',
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)

const AccessedPill = styled.div(
  s(
    'rounded-3xl bg-accent-red flex items-center px-2 text-grey-900 text-xs font-normal h-1 ml-4',
    {
      marginTop: 3,
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  ),
  ({ hasOnlineAccess }) => hasOnlineAccess && s('hidden')
)

const InvitedPill = styled.div(
  s(
    'rounded-3xl bg-grey-100 flex items-center px-2 text-grey-900 text-xs font-normal h-1 ml-4',
    {
      marginTop: 3,
      letterSpacing: '0.015em',
      lineHeight: 1.5,
      paddingBottom: 2,
      paddingTop: 2,
    }
  )
)

const ContentCard = styled(ContentCardBase)(s('mb-9 p-5'))

const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const LoaderContainer = styled.div(s('flex justify-center items-center h-8'))

const getAddressType = (address) => {
  if (isNilOrEmpty(address?.type)) return 'Mailing Address'
  return `${formatters.capitaliseFirstLetter(address.type)} Address`
}

const getEntityDetailsTitle = (entityType) => {
  switch (entityType) {
    case COMPANY:
      return 'Business Details'

    case TRUST:
      return 'Trust Details'

    default:
      return 'Personal Details'
  }
}

// eslint-disable-next-line complexity
const PersonalDetails = ({ isLoading, customer = {}, invitation = {} }) => {
  const { name, email, phoneNumber, customerNumber, addresses = [], type } = customer

  const resentAt = formatDateString(invitation.updatedAt)

  const isInvitedPending =
    isNotNilOrEmpty(invitation) && invitation.status !== INVITATION_STATUSES.ACCEPTED

  const isInvitedResent =
    isNotNilOrEmpty(invitation) && invitation.status === INVITATION_STATUSES.REINVITED

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col span={24}>
            <SectionTitle title="Entity Details" />
            <ContentCard>
              <LoaderContainer>
                <DottedCircleLoader size="md" />
              </LoaderContainer>
            </ContentCard>
          </Col>

          <Col span={24}>
            <SectionTitle title="Contact Details" />
            <ContentCard>
              <LoaderContainer>
                <DottedCircleLoader size="md" />
              </LoaderContainer>
            </ContentCard>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col span={24}>
          <SectionTitle title={getEntityDetailsTitle(type)} />
          <ContentCard>
            <Row>
              <Col span={24}>
                <div style={s('flex flex-row')}>
                  <Label>Legal Name</Label>
                  {isInvitedPending && <InvitedPill>INVITE PENDING</InvitedPill>}
                  {isInvitedResent && (
                    <InvitedPill>{`INVITE RESENT ${resentAt}`}</InvitedPill>
                  )}
                  <AccessedPill hasOnlineAccess={customerNumber}>
                    NO ONLINE ACCESS
                  </AccessedPill>
                </div>
                <Value>{name || '-'}</Value>
              </Col>
              <Col span={24}>
                <DataDisplay label="Cust No." value={customerNumber} />
              </Col>
            </Row>
          </ContentCard>
        </Col>

        <Col span={24}>
          <SectionTitle title="Contact Details" />
          <ContentCard>
            <Row>
              <Col span={24}>
                <DataDisplay label="Email Address" value={email} />
              </Col>
              <Col span={24}>
                <DataDisplay
                  label="Mobile Phone Number"
                  value={formatters.formatPhoneNumber(phoneNumber)}
                />
              </Col>
              {addresses.map((address, index) => {
                const fullAddress = formatters.buildAddress(
                  {
                    streetName: address?.streetName,
                    streetNumber: address?.streetNumber,
                    streetType: address?.streetType,
                  },
                  address?.suburb,
                  address?.territorialAuthorityName,
                  address?.countryISO,
                  address?.streetUnitNumber,
                  address?.postCode,
                  address?.fullAddress
                )

                if (fullAddress === '-') return null

                return (
                  <Col key={index} span={24}>
                    <DataDisplay label={getAddressType(address)} value={fullAddress} />
                  </Col>
                )
              })}
            </Row>
          </ContentCard>
        </Col>
      </Row>
    </Container>
  )
}

export { PersonalDetails }
