import React from 'react'
import { Row, Col, Container as ContainerBase } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { DottedCircleLoader } from '@vega/components'
import { formatCurrency, formatMonthsToYears } from 'utils/formatter'
import { TEST_IDS } from '@vega/constants'
import remainingLoanTerm from 'utils/date/remaningLoanTerm'

const Container = styled(ContainerBase)(
  s('flex flex-column border-grey-200 rounded-lg p-5 bg-white', {
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
    height: 215,
  })
)
const ValueTitle = styled.div(s('font-normal text-sm text-grey-600'))
const Value = styled.div(s('mt-1 text-base text-grey-900 font-semibold'), {
  whiteSpace: 'nowrap',
})

function LoanBox({
  isLoading,
  loanBalance,
  loanRepaymentAmount,
  loanRepaymentRecurrence,
  loanAmount,
  loanTerm,
  settlementDate,
}) {
  if (isLoading) {
    return (
      <Container style={s('flex items-center justify-center h-10')}>
        <DottedCircleLoader size="lg" />
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Col span={24}>
          <ValueTitle>Loan Amount</ValueTitle>
          <Value data-test-id={TEST_IDS.LOAN_SIZE}>
            {formatCurrency(loanAmount, 2)}
          </Value>
        </Col>
      </Row>

      <Row style={s('mt-4')}>
        <Col span={12}>
          <ValueTitle>Loan Term</ValueTitle>
          <Value>{`${formatMonthsToYears(loanTerm)}`}</Value>
        </Col>
        <Col span={12}>
          <ValueTitle>Remaining Loan Term</ValueTitle>
          <Value>{remainingLoanTerm(loanTerm, settlementDate)}</Value>
        </Col>
      </Row>

      <Row style={s('mt-4')}>
        <Col span={12}>
          <ValueTitle>Current Loan Balance</ValueTitle>
          <Value data-test-id={TEST_IDS.CURRENT_LOAN_BALANCE}>
            {formatCurrency(loanBalance, 2)}
          </Value>
        </Col>

        <Col span={12}>
          <ValueTitle>Repayment Amount</ValueTitle>
          <Value data-test-id={TEST_IDS.REPAYMENT_AMOUNT}>{`${formatCurrency(
            loanRepaymentAmount,
            2
          )} ${loanRepaymentRecurrence}`}</Value>
        </Col>
      </Row>
    </Container>
  )
}

export default LoanBox
