import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Validator from 'yup'
import { Formik, Form } from 'formik'
import { s, styled } from '@vega/styled/v2'
import { Modal, TextField as TextFieldBase } from '@vega/components'
import { ReactComponent as XBase } from '@vega/components/src/assets/images/x.svg'
import { ReactComponent as ArrowTopRightBase } from '@vega/components/src/assets/images/arrow-top-right.svg'
import { approveLoanGroup } from 'modules/loanGroup'
import { selectIsFetchMortgageAccountsPending } from 'modules/mortgageAccounts'
import { fetchTransactions, setIsFetchingTransactions } from 'modules/transactions'
import formatters from 'utils/formatters'
import { ApprovingLoan } from './ApprovingLoan'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { TEST_IDS } from '@vega/constants'

const Container = styled.div(
  s('p-6 bg-white', {
    borderRadius: 12,
    boxShadow: '0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)',
    maxWidth: 703,
  })
)
const Header = styled.div(s('flex justify-between'))
const Title = styled.div(
  s('text-xl text-grey-900 font-semibold', {
    letterSpacing: '-0.01em',
    lineHeight: 1.2,
    marginBottom: 28,
  })
)
const X = styled(XBase)(s('text-primary', { cursor: 'pointer' }))
const ArrowTopRight = styled(ArrowTopRightBase)(s('text-primary'))
const TextContainer = styled.div(s('mb-4 bg-grey-100', { padding: 6 }))
const Heading = styled.div(
  s('mb-3 text-base text-grey-900 font-semibold', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const Text = styled.div(
  s('tet-sm text-grey-900 font-normal', { letterSpacing: '0.01em', lineHeight: 1.5 })
)
const List = styled.ul(s('mt-0'))
const ListItem = styled.li(
  s('text-base text-grey-900 font-normal', { lineHeight: 1.5 })
)
const ReviewLinkContainer = styled.div(s('flex items-center mb-6'), {
  cursor: 'pointer',
})
const ReviewLink = styled.div(
  s('mr-1 text-sm font-semibold text-primary', {
    letterSpacing: '0.01em',
    lineHeight: 1.5,
  })
)
const BoldText = styled.span(s('font-semibold'))
const TextField = styled(TextFieldBase)(s('mb-8', { minWidth: 430 }))
const Button = styled.button(
  s(
    'flex justify-center w-full border-0 bg-secondary text-primary text-base font-semibold',
    {
      borderRadius: 6,
      cursor: 'pointer',
      letterSpacing: '0.01em',
      lineHeight: 1.5,
      paddingBottom: 10,
      paddingTop: 10,
    }
  ),
  ({ isDisabled }) => isDisabled && s('bg-grey-200 text-grey-600')
)

const { object, string } = Validator

const validationSchema = (confirmation) =>
  object({
    confirmation: string().oneOf([confirmation]).required('Confirmation is required'),
  })

const ApproveLoanGroup = ({
  isOpen,
  setIsOpen,
  loans,
  loanGroupId,
  loanGroupNumber,
}) => {
  const dispatch = useDispatch()
  const { id: loanId } = useParams()
  const isFetchingLoans = useSelector(selectIsFetchMortgageAccountsPending)

  const close = () => setIsOpen(false)

  // TODO: change to redirect to a real url once the destination is confirmed
  const goToApplication = () => 'Review Application'

  const handleSubmit = async () => {
    await dispatch(approveLoanGroup(loanGroupId))
    await dispatch(setIsFetchingTransactions(true))

    setTimeout(async () => {
      await dispatch(fetchTransactions(loanId))
      await dispatch(setIsFetchingTransactions(false))
    }, 2500)

    close()
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Container>
        <Header>
          <Title>Approve Application Loan Group (ID: {loanGroupNumber})</Title>
          <X onClick={close} />
        </Header>

        <TextContainer>
          <Text>Before approving these loans, please review a few items:</Text>
          <List>
            <ListItem>Have you reviewed the settlement documents?</ListItem>
            <ListItem>
              Does the customer, and product information meet responsible lending
              criteria, and is fit for purpose?
            </ListItem>
          </List>
          <Text>
            If not, please come back later. You <BoldText>CANNOT</BoldText> undo this
            task.
          </Text>
        </TextContainer>

        <ReviewLinkContainer onClick={goToApplication}>
          <ReviewLink>Review Application</ReviewLink>
          <ArrowTopRight />
        </ReviewLinkContainer>

        <Heading>Approving loans:</Heading>

        {loans?.map(({ loanAmount, loanNumber, product }, index) => {
          const formattedLoanAmount = formatters.formatMonetaryAmount(loanAmount)
          const formattedProductInfo = product?.displayName

          return (
            <ApprovingLoan
              key={index}
              loanAmount={formattedLoanAmount}
              loanNumber={loanNumber}
              productInfo={formattedProductInfo}
            />
          )
        })}

        <Formik
          initialValues={{ confirmation: '' }}
          validationSchema={useCallback(() => validationSchema(loanGroupNumber), [
            loanGroupNumber,
          ])}
          onSubmit={handleSubmit}
        >
          {({ isValid, values }) => {
            const shouldDisableSubmitBtn =
              !isValid || isNilOrEmpty(values?.confirmation) || isFetchingLoans

            return (
              <Form>
                <Text style={s('mb-1')}>
                  Confirm by typing <BoldText>the Loan Group ID</BoldText> below
                </Text>
                <TextField
                  name="confirmation"
                  placeholder={loanGroupNumber}
                  testId={TEST_IDS.APPROVE_CONFIRM_INPUT}
                />

                <Button
                  type="submit"
                  isDisabled={shouldDisableSubmitBtn}
                  data-test-id={TEST_IDS.APPROVE_ALL_LOANS_BTN}
                >
                  Approve All Loans
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Modal>
  )
}

export { ApproveLoanGroup }
