import React, { useEffect } from 'react'
import {
  selectIsFetchCustomerLoansPending,
  selectCustomerLoans,
  fetchCustomerLoans,
  selectCurrentUserCustomerId,
} from 'modules/customer'
import { isNotNilOrEmpty } from '@neo/ramda-extra'
import { useSelector, useDispatch } from 'react-redux'
import { LoanOverviewContainer } from './LoanOverviewContainer'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery, larger, smaller } from '@vega/styled'
import PaymentCenterNavigation from 'features/customer/paymentSchedule/PaymentCenterNavigation'

const AllLoans = () => {
  const isLargerThanLargeScreen = useLargeMediaQuery('larger')
  const PageContainer = styled.div(
    s('px-0'),
    smaller('md')(s('px-8')),
    ({ isLarge }) => isLarge && s('px-8')
  )

  const PaymentCenterTitle = styled.div(
    s('text-3xl text-primary font-semibold'),
    {
      whiteSpace: 'nowrap',
      letterSpacing: '-0.02em',
    },
    larger('lg')(s('py-6', { fontSize: 28 }))
  )

  const HeaderContainer = styled.div(
    s('flex flex-column px-8 py-8 w-full mx-a '),
    ({ isLargeScreen }) => isLargeScreen && s('mb-10')
  )

  const isFetchingLoans = useSelector(selectIsFetchCustomerLoansPending)
  const isLoading = isFetchingLoans
  const loans = useSelector(selectCustomerLoans)
  const dispatch = useDispatch()

  const customerId = useSelector(selectCurrentUserCustomerId)

  useEffect(() => {
    if (isNotNilOrEmpty(customerId)) {
      dispatch(fetchCustomerLoans({ customerId }))
    }
  }, [customerId, dispatch])

  return (
    <div style={s('flex flex-column flex-1', { overflow: 'auto' })}>
      <HeaderContainer>
        <Row justify="center">
          <Col span={24} lg={16}>
            <PaymentCenterTitle className="sohne" isLarge={isLargerThanLargeScreen}>
              Payment Centre
            </PaymentCenterTitle>
          </Col>
          <Col span={0} lg={5} />
        </Row>
      </HeaderContainer>

      <PageContainer isLarge={isLargerThanLargeScreen}>
        <Row justify="center">
          <Col span={24} lg={16}>
            <PaymentCenterNavigation />
            <LoanOverviewContainer loans={loans} isLoading={isLoading} />
          </Col>
          <Col span={0} lg={5} />
        </Row>
      </PageContainer>
    </div>
  )
}

export default AllLoans
