import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PaidPaymentsContainer } from './PaidPaymentsContainer'
import { sub } from 'date-fns'
import {
  selectIsFetchCustomerPaymentsPending,
  selectIsFetchCustomerLoansPending,
  selectCustomerPayments,
  selectCustomerLoans,
  fetchCustomerPayments,
  fetchCustomerLoans,
  selectCurrentUserCustomerId,
  selectNoMorePayments,
} from 'modules/customer'

import { SORT_ORDER } from '@vega/constants'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery, larger, smaller } from '@vega/styled'
import PaymentCenterNavigation from 'features/customer/paymentSchedule/PaymentCenterNavigation'
import { isNotNilOrEmpty } from '@neo/ramda-extra'

function PaidPayments() {
  const isLargerThanLargeScreen = useLargeMediaQuery('larger')
  const PageContainer = styled.div(
    s('px-0'),
    smaller('md')(s('px-8')),
    ({ isLarge }) => isLarge && s('px-8')
  )
  const Scrollable = styled.div(s('flex flex-column flex-1', { overflow: 'auto' }))
  const PaymentCenterTitle = styled.div(
    s('text-3xl text-primary font-semibold'),
    {
      whiteSpace: 'nowrap',
      letterSpacing: '-0.02em',
    },
    larger('lg')(s('py-6', { fontSize: 28 }))
  )

  const HeaderContainer = styled.div(
    s('flex flex-column px-8 py-8 w-full mx-a '),
    ({ isLargeScreen }) => isLargeScreen && s('mb-10')
  )
  const isFetchingLoans = useSelector(selectIsFetchCustomerLoansPending)
  const isFetchingPayments = useSelector(selectIsFetchCustomerPaymentsPending)
  const isLoading = isFetchingPayments || isFetchingLoans
  const dispatch = useDispatch()

  const customerId = useSelector(selectCurrentUserCustomerId)

  const loans = useSelector(selectCustomerLoans)
  const payments = useSelector(selectCustomerPayments) || []
  const hasNoMorePayments = useSelector(selectNoMorePayments)

  useEffect(() => {
    if (isNotNilOrEmpty(customerId)) {
      const fiveMonthsAgoFromToday = sub(new Date(), { months: 5 })
      const paymentFilters = {
        paymentStatuses: ['paid'],
        paymentDueDateMonth: fiveMonthsAgoFromToday.getMonth() + 1,
        paymentDueDateYear: fiveMonthsAgoFromToday.getFullYear(),
        additionalMonths: 6,
      }
      const paymentSorting = {
        dueDate: SORT_ORDER.DESC,
      }

      dispatch(fetchCustomerLoans({ customerId }))
      dispatch(
        fetchCustomerPayments({
          customerId,
          filters: paymentFilters,
          sorting: paymentSorting,
        })
      )
    }
  }, [dispatch, customerId])

  const getMorePayments = (latestDueDateString) => {
    const latestDueDate = new Date(latestDueDateString)
    const firstNewUpcomingPayment = sub(latestDueDate, { months: 7 })
    const paymentFilters = {
      paymentStatuses: ['paid'],
      paymentDueDateMonth: firstNewUpcomingPayment.getMonth() + 1,
      paymentDueDateYear: firstNewUpcomingPayment.getFullYear(),
      additionalMonths: 6,
    }
    const paymentSorting = {
      dueDate: SORT_ORDER.DESC,
    }
    dispatch(
      fetchCustomerPayments({
        customerId,
        filters: paymentFilters,
        sorting: paymentSorting,
        shouldAppend: true,
      })
    )
  }

  return (
    <Scrollable>
      <HeaderContainer>
        <Row justify="center">
          <Col span={24} lg={16}>
            <PaymentCenterTitle className="sohne" isLarge={isLargerThanLargeScreen}>
              Payment Centre
            </PaymentCenterTitle>
          </Col>
          <Col span={0} lg={5} />
        </Row>
      </HeaderContainer>
      <PageContainer isLarge={isLargerThanLargeScreen}>
        <Row justify="center">
          <Col span={24} lg={16}>
            <PaymentCenterNavigation />
            <PaidPaymentsContainer
              loans={loans}
              payments={payments}
              getMorePayments={getMorePayments}
              hasNoMorePayments={hasNoMorePayments}
              isLoading={isLoading}
            />
          </Col>
          <Col span={0} lg={5} />
        </Row>
      </PageContainer>
    </Scrollable>
  )
}

export default PaidPayments
