import React from 'react'
import { s } from '@vega/styled'
import { noop } from '@neo/ramda-extra'
import { Overlay } from '@vega/components'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import DateFnsAdapter from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import DateRangePicker from '@mui/lab/StaticDateRangePicker'

function OverlayDateRangePicker({ value = [null, null], onChange = noop }) {
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <Overlay
        label={value[0] !== null ? 'Date Range Picked' : 'Date range'}
        popoverStyle={s('border-solid border-1 border-grey-200 bg-white', {
          borderRadius: 12,
        })}
      >
        <DateRangePicker
          value={value}
          onChange={onChange}
          renderInput={(startProps, endProps) => {
            return (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )
          }}
        />
      </Overlay>
    </LocalizationProvider>
  )
}

export default OverlayDateRangePicker
