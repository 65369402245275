import React from 'react'
import { Item } from '@react-stately/collections'
import { s, styled } from '@vega/styled/v2'
import { Button as ButtonBase, DropdownCheckbox } from '@vega/components'
import { ReactComponent as ResetIcon } from '@vega/components/src/assets/images/refresh.svg'

import {
  paymentTypeFilterOptions,
  methodFilterOptions,
  statusFilterOptions,
  overdueFilterOptions,
} from './filterOptions'
import { noop } from '@neo/ramda-extra'

const Container = styled.div(s('flex justify-between', { gap: 10 }))

const Button = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-200 py-3 px-4 text-sm text-grey-600 rounded-lg'
  )
)

function FiltersBar({ updateSearchProps, filters }) {
  const handleFilterAction = (targetFilter) => (key) => {
    const current = filters[targetFilter]

    const isKeyUnchecked = current.includes(key)

    const updated = isKeyUnchecked
      ? current.filter((k) => k !== key)
      : [...current, key]

    return updateSearchProps({
      filters: { [targetFilter]: updated },
    })
  }

  function handleResetFilters() {
    updateSearchProps({
      filters: {
        type: [],
        method: [],
        date: [],
        status: [],
        overdue: [],
      },
    })
  }

  return (
    <Container>
      <DropdownCheckbox
        label="Payment type"
        aria-label="payment-type"
        onAction={handleFilterAction('type')}
        selectedKeys={filters.type}
      >
        {paymentTypeFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      {/* TODO: as we only have one method should we keep this filter? */}
      <DropdownCheckbox
        label="Method"
        aria-label="method"
        onAction={noop}
        selectedKeys={[methodFilterOptions[0].key]}
      >
        {methodFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      {/* TODO: replace this with date picker component */}
      <DropdownCheckbox
        label="1/12/2021"
        aria-label="date"
        onAction={handleFilterAction('date')}
      />

      <DropdownCheckbox
        label="Status"
        aria-label="status"
        onAction={handleFilterAction('status')}
        selectedKeys={filters.status}
      >
        {statusFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      <DropdownCheckbox
        label="Overdue"
        aria-label="overdue"
        onAction={handleFilterAction('overdue')}
        selectedKeys={filters.overdue}
      >
        {overdueFilterOptions.map((option) => (
          <Item key={option.key}>{option.label}</Item>
        ))}
      </DropdownCheckbox>

      <Button
        StartIcon={<ResetIcon style={s('w-1 h-1 text-grey-400 mr-1')} />}
        onClick={handleResetFilters}
      >
        Reset
      </Button>
    </Container>
  )
}

export default FiltersBar
