import BaseService from '../baseService'
import { QueryBuilder } from '../utils'
import { omit, isNilOrEmpty } from '@neo/ramda-extra'

export class PaymentService extends BaseService {
  async fetchPaymentsExport({ filters = {} }) {
    const searchParams = QueryBuilder().setFilters(filters).build()
    const buffer = await this.client
      .get(`analytics/payment-export?${searchParams}`)
      .arrayBuffer()
    convertAndSave('export payments', buffer)
  }

  fetchPayments({ q, start, limit, sorting = [], filters = {} }, signal) {
    const searchParams = QueryBuilder()
      .setQ(q)
      .setFilters(omit(['loanId'], filters))
      .setPaging({ start, limit })
      .setSorting(sorting)
      .setParam('loanId', filters.loanId)
      .build()

    return this.client.get(`payments?${searchParams}`, { signal }).json()
  }

  settlePayment(payload) {
    let json = payload

    if (isNilOrEmpty(payload.notes)) {
      json = omit(['notes'], json)
    }

    return this.client.patch(`payments`, { json })
  }

  sendEmail(paymentId) {
    return this.client.patch(`payments/send-email/${paymentId}`)
  }

  processPayment(payment) {
    return new Promise((resolve) =>
      setTimeout(() => {
        resolve(payment)
      }, 1000)
    )
  }
}

function convertAndSave(reportName, contentInByte) {
  const blob = new Blob([contentInByte], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  const fileName = reportName
  link.download = fileName
  link.click()
}
