import { prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { productsAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectProducts = prop('products')

export const selectTotal = createSelector(selectProducts, prop('total'))

export const {
  selectIsPending: selectIsFetchProductsPending,
} = createAsyncThunkSelectors('products/fetchProducts')

export const {
  selectIsPending: selectIsFetchProductPending,
  selectHasFulfilled: selectHasFetchProductFulfilled,
  selectError: selectFetchProductError,
} = createAsyncThunkSelectors('products/fetchProduct')

export const {
  selectEntities: selectProductEntities,
  selectAll: selectAllProducts,
  selectById: selectProductById,
} = productsAdapter.getSelectors(prop('products'))
