import { createSelector } from '@reduxjs/toolkit'
import { prop } from '@neo/ramda-extra'
import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'
import { paymentsAdapter } from './module'

const selectPayments = prop('payments')

export const {
  selectIsPending: selectIsFetchingPayments,
  selectStatus: selectFetchPaymentsStatus,
} = createAsyncThunkSelectors('payments/fetchPayments')

export const selectTotal = createSelector(selectPayments, prop('total'))
export const selectAllPayment = createSelector(selectPayments, prop('entities'))

export const {
  selectAll: selectAllPayments,
  selectById: selectPaymentById,
} = paymentsAdapter.getSelectors(prop('payments'))
