/* eslint-disable complexity */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNotNilOrEmpty, isNilOrEmpty } from '@neo/ramda-extra'
import { SORT_ORDER, TRANSACTION_STATUS } from '@vega/constants'
import {
  fetchCustomerLoans,
  fetchCustomerBroker,
  selectCustomerLoanGroups,
  selectIsFetchCustomerLoansPending,
  selectCurrentUserCustomerId,
  fetchCustomerPayments,
  selectCustomerPayments,
  selectCustomerLoans,
} from 'modules/customer'
import { selectIsFetchingPreferences } from 'modules/preference'
import { Row, Col, useLargeMediaQuery, larger, smaller } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { Loading } from '@vega/components'
import MortgageCard from './MortgageCard'
import { UpcomingPayments } from './UpcomingPayments'
import { InfoSideContainer } from './InfoSideContainer/InfoSideContainer'
import { useHistory } from 'react-router-dom'
import { routes } from 'routes'

const Root = styled.div(s('flex flex-column flex-1 self-center w-full h-full py-8 '), {
  overflow: 'hidden',
})

const MortgageCardContainer = styled.div(
  s('bg-white rounded-lg px-4', {
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
  })
)

const Scrollable = styled.div(
  s('flex-1', {
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: -32,
  }),
  smaller('md')(s('px-6'))
)

const ListViewContainer = styled(Col)(larger('lg')(s('mr-8')))

const CardHeading = styled.h3(s('text-primary text-xl mb-3'))

const monthsToYears = (months) => {
  try {
    if (isNilOrEmpty(months)) return ''

    return months / 12
  } catch (error) {
    return ''
  }
}

function HomePage() {
  const dispatch = useDispatch()
  const isLargeScreen = useLargeMediaQuery('larger')

  const customerId = useSelector(selectCurrentUserCustomerId)

  const isFetchingLoans = useSelector(selectIsFetchCustomerLoansPending)
  const isFetchingPreferences = useSelector(selectIsFetchingPreferences)

  const loanGroups = useSelector(selectCustomerLoanGroups)
  const homeLoans = loanGroups.map(({ status, loans }) => ({ status, loans }))
  const loans = useSelector(selectCustomerLoans)

  const payments = useSelector(selectCustomerPayments) || []
  const unpaidPayments = []
  const history = useHistory()

  payments.forEach((payment) => {
    if (payment.status !== TRANSACTION_STATUS.PAID) {
      unpaidPayments.push(payment)
    }
  })

  useEffect(() => {
    if (isNotNilOrEmpty(customerId)) {
      const paymentFilters = {
        paymentStatuses: ['unpaid', 'pending'],
        paymentDueDateMonth: new Date().getMonth() + 1,
        paymentDueDateYear: new Date().getFullYear(),
      }
      const paymentSorting = {
        dueDate: SORT_ORDER.ASC,
      }

      dispatch(fetchCustomerLoans({ customerId }))
      dispatch(
        fetchCustomerPayments({
          customerId,
          filters: paymentFilters,
          sorting: paymentSorting,
        })
      )
      dispatch(fetchCustomerBroker(customerId))
    }
  }, [dispatch, customerId])

  if (isFetchingLoans || isFetchingPreferences) return <Loading />

  return (
    <Root>
      <Scrollable isLargeScreen={isLargeScreen}>
        <Row justify="center" >
          <ListViewContainer span={24} lg={11}>
            <>
              <CardHeading className="sohne">Your Home Loans</CardHeading>

              <MortgageCardContainer key={1}>
                {homeLoans.map(({ loans = [], status }) => {
                  return loans.map(
                    (
                      {
                        _id,
                        loanNumber,
                        loanLimit,
                        balance,
                        product,
                        loanTermInMonths,
                      },
                      index
                    ) => (
                      <MortgageCard
                        onClick={() =>
                          history.push(`${routes.customer.myLoans.root}/${_id}`)
                        }
                        key={index}
                        name={product?.displayName}
                        accountNumber={loanNumber}
                        loanLimit={loanLimit}
                        loanBalance={balance}
                        loanDuration={monthsToYears(loanTermInMonths)}
                        loanGroupStatus={status}
                        repaymentType={product?.repaymentType}
                      />
                    )
                  )
                })}
              </MortgageCardContainer>
            </>

            <CardHeading className="sohne" style={{ marginTop: 40 }}>
              Upcoming Payments
            </CardHeading>
            <MortgageCardContainer>
              <UpcomingPayments
                loans={loans}
                payments={unpaidPayments}
                date={new Date()}
              />
            </MortgageCardContainer>
          </ListViewContainer>

          <Col span={0} lg={8}>
            <InfoSideContainer />
          </Col>
        </Row>

        {!isLargeScreen && (
          <Row style={s('mb-16')}>
            <Col span={24} lg={0}>
              <InfoSideContainer />
            </Col>
          </Row>
        )}
      </Scrollable>
    </Root>
  )
}

export default HomePage
