import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { productService } from 'apiService'
import { parseDate } from 'utils/date'
import { normalizeError } from '@vega/services'
import { PRODUCT_CONSTANTS } from '@vega/constants'

const { PRODUCT_STATUS } = PRODUCT_CONSTANTS

export const createProduct = createAsyncThunk(
  'products/createProduct',
  async (payload, { rejectWithValue }) => {
    try {
      return await productService.create({
        ...payload,
        fields: {
          ...payload.fields,
          validFrom: parseDate(payload.fields.validFrom),
          validTo: parseDate(payload.fields.validTo),
        },
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async ({ searchParams, pageIndex }, { rejectWithValue, signal }) => {
    try {
      const { searchTerm: q, filters = {}, limit = 20 } = searchParams
      return await productService.fetchProducts(
        {
          q,
          filters,
          start: limit * pageIndex,
          limit,
        },
        signal
      )
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const fetchProduct = createAsyncThunk(
  'products/fetchProduct',
  async (id, { rejectWithValue }) => {
    try {
      return await productService.fetchProduct(id)
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error.message)
    }
  }
)

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  // eslint-disable-next-line complexity
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      const updatingProps = {
        ...payload,
        fields: {
          ...payload.fields,
          validFrom: parseDate(payload.fields.validFrom),
          validTo: parseDate(payload.fields.validTo),
        },
      }

      return await productService.update({ id, ...updatingProps })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const archiveProduct = createAsyncThunk(
  'products/updateProduct',
  async (id, { rejectWithValue }) => {
    try {
      return await productService.update({
        id,
        status: PRODUCT_STATUS.ARCHIVED,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const restoreProduct = createAsyncThunk(
  'products/updateProduct',
  async (id, { rejectWithValue }) => {
    try {
      return await productService.update({
        id,
        status: PRODUCT_STATUS.ACTIVE,
      })
    } catch (err) {
      const error = await normalizeError(err)
      return rejectWithValue(error)
    }
  }
)

export const productsAdapter = createEntityAdapter()

const initialState = productsAdapter.getInitialState({
  entities: {},
  ids: [],
  total: undefined,
})

const productsSlice = createSlice({
  name: 'products',
  initialState,
  extraReducers: {
    [createProduct.fulfilled]: productsAdapter.addOne,
    [fetchProduct.fulfilled]: productsAdapter.upsertOne,
    [fetchProducts.fulfilled]: (state, action) => {
      const { items: products, pagination } = action.payload

      productsAdapter.setAll(state, products)
      state.total = pagination.total
    },
    [updateProduct.fulfilled]: (state, { payload }) => {
      const { id, ...changes } = payload
      productsAdapter.updateOne(state, { id, changes })
    },
  },
})

const { reducer: productsReducer } = productsSlice
export { productsReducer }
