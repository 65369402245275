import React from 'react'
import { Map } from './Map'
import { styled, s } from '@vega/styled/v2'
import { useMortgageMapData } from './useMortgageMapData'

import { TERRITORIAL_AUTHORITY } from '@vega/constants'
import { LocationItem } from './LocationItem'

const Container = styled.div(
  s('flex flex-column bg-white  pt-5', {
    width: 750,
    height: 486,
  })
)

const LocationListContainer = styled.div(
  s('', {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  })
)

const Row = styled.div(s('flex justify-between'))

const citiesAndDistricts = Object.keys(TERRITORIAL_AUTHORITY)
const descendingComparer = (a, b) => b.percentage - a.percentage

export const MortgageMap = ({ mortgageCountByLocation = {} }) => {
  const { data, minValue, maxValue } = useMortgageMapData(
    mortgageCountByLocation,
    citiesAndDistricts
  )

  return (
    <Container>
      <Row style={s('w-full px-10', { height: 420 })}>
        <Map
          data={data}
          minValue={minValue}
          maxValue={maxValue}
          containerStyle={s('h-full', { width: '60%' })}
          centerOffsetY={-120}
          centerOffsetX={20}
          scale={1100}
        />

        <LocationListContainer>
          {data.sort(descendingComparer).map((props) => (
            <LocationItem key={props.location} style={s('mb-4')} {...props} />
          ))}
        </LocationListContainer>
      </Row>
    </Container>
  )
}
