import React from 'react'
import { styled, s } from '@vega/styled/v2'
import { Row, Col, useLargeMediaQuery } from '@vega/styled'
import PaymentCard from './PaymentCard'
import { safelyParseDate } from 'utils/date'
import { Loading } from '@vega/components'
import { isNilOrEmpty } from '@neo/ramda-extra'

const Root = styled.div(s('bg-white rounded-lg flex flex-column px-5 pb-6 py-2'))

const ViewMoreButton = styled.button(
  s('border-transparent bg-secondary py-1 font-semibold text-sm', { borderRadius: 6 }),
  ({ hidden }) => hidden && s('hidden')
)

const isOverdue = (paymentDateString) => {
  const todaysMonth = new Date().getMonth()
  const paymentMonth = new Date(paymentDateString).getMonth()
  const todaysYear = new Date().getFullYear()
  const paymentYear = new Date(paymentDateString).getFullYear()
  return todaysMonth < paymentMonth && todaysYear < paymentYear
}

const isSameAsPreviousMonth = (currentDateString, previousDateString) => {
  const currentMonth = new Date(currentDateString).getMonth()
  const previousMonth = new Date(previousDateString).getMonth()
  const currentYear = new Date(currentDateString).getFullYear()
  const previousYear = new Date(previousDateString).getFullYear()
  return (
    currentMonth === previousMonth &&
    currentYear === previousYear &&
    !isOverdue(previousDateString)
  )
}

export const PaidPaymentsContainer = ({
  loans,
  payments,
  getMorePayments,
  hasNoMorePayments,
  isLoading,
}) => {
  const aboveLargeScreenSize = useLargeMediaQuery('larger')

  if (isLoading) {
    return (
      <Root isLargeScreen={aboveLargeScreenSize} style={s('py-16')}>
        <Loading />
      </Root>
    )
  }

  if (isNilOrEmpty(payments)) {
    return (
      <Root isLargeScreen={aboveLargeScreenSize} className="poppins">
        <div style={s('text-center text-grey-700 font-normal text-base my-16')}>
          No paid payments
        </div>
      </Root>
    )
  }

  return (
    <Root isLargeScreen={aboveLargeScreenSize}>
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Root>
            {payments.map((payment, index) => (
              <>
                <div key={index} style={s('mt-2')}>
                  {!isSameAsPreviousMonth(
                    payment?.dueDate,
                    payments[index - 1]?.dueDate
                  ) && (
                    <div style={s('flex-row bg-grey-100 py-2 pl-4 font-semibold', { borderRadius: 6 })}
                      key={index}
                      hidden={isOverdue(payment?.dueDate)}
                    >
                      {safelyParseDate(payment?.dueDate, 'MMMM')}{' '}
                      {safelyParseDate(payment?.dueDate, 'yyyy')}
                    </div>
                  )}
                  <PaymentCard
                    loan={loans?.find((loan) => loan._id === payment.loanId)}
                    payment={payment}
                  />
                </div>
              </>
            ))}

            <ViewMoreButton
              onClick={() => {
                getMorePayments(payments.at(-1)?.dueDate)
              }}
              hidden={hasNoMorePayments}
            >
              Load More Payments
            </ViewMoreButton>
          </Root>
        </Col>
      </Row>
    </Root>
  )
}
