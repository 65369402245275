import React from 'react'

import HomePageOverview from 'features/admin/homePage/homePageOverview'
import { Formik } from 'formik'
import { noop } from '@neo/ramda-extra'

const currentMonth = new Date().getMonth() + 1
const currentYear = new Date().getFullYear()

const homePage = () => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        currentMonth,
        currentYear,
        topClientCriterion: 'borrowingAmount',
      }}
      onSubmit={noop}
    >
      <HomePageOverview />
    </Formik>
  )
}

export default homePage
