import React from 'react'
import { prop } from '@neo/ramda-extra'
import ParentSize from '@visx/responsive/lib/components/ParentSize'
import { MapBase } from './MapBase'
import { styled, s } from '@vega/styled/v2'

const Container = styled.div(s(''), prop('containerStyle'))

export const Map = ({ data, containerStyle, ...otherProps }) => {
  return (
    <Container containerStyle={containerStyle}>
      <ParentSize>
        {({ width: parentWidth, height: parentHeight }) => (
          <MapBase
            data={data}
            width={parentWidth}
            height={parentHeight}
            {...otherProps}
          />
        )}
      </ParentSize>
    </Container>
  )
}
