/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Row, Col, larger } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'

import { isNilOrEmpty } from '@neo/ramda-extra'
import { formatCurrency, formatPercentage } from 'utils/formatter'
import { ReactComponent as ChevronUp } from '@vega/components/src/assets/images/chevron-up.svg'

const CollapsableBox = styled.div(
  s('px-8 py-6'),
  { background: 'rgba(241, 241, 232, 0.58)' },
  larger('lg')(s('px-6'))
)

const PropertyLabel = styled.span(s('text-xs font-semibold'), { color: '#490B2D' })

const CollapseButton = styled.div(
  s('pt-6 text-center text-sm font-semibold text-primary'),
  { cursor: 'pointer' },
  larger('lg')(s('px-6'))
)

const FieldLabel = styled.span(
  s('font-normal text-base text-grey-600', {
    lineHeight: '150%',
  }),
  larger('lg')(s('text-sm'))
)
const ChevronUpIcon = styled(ChevronUp)(s('ml-2'), {
  cursor: 'pointer',
  path: { stroke: s('text-primry').color },
})
const Value = styled.span(
  s('font-semibold text-base font-bold', {
    lineHeight: '150%',
    color: '#141616',
  }),
  larger('lg')(s('text-sm'))
)

const formatAddress = (address) => {
  try {
    if (isNilOrEmpty(address?.fullAddress)) return '-'
    return (
      `${address?.fullAddress}`
        .toLocaleLowerCase()
        // Upper case first letter of each word
        .replace(/\b\w/g, (c) => c.toUpperCase())
    )
  } catch (error) {
    return '-'
  }
}

const PropertyItem = ({ property = {}, listOrder = 1, ...otherProps }) => {
  return (
    <div {...otherProps}>
      <Row style={s('mb-1')}>
        <Col span={24}>
          <PropertyLabel>PROPERTY {listOrder}</PropertyLabel>
        </Col>
      </Row>

      <Row style={s('mb-1')}>
        <Col span={24}>
          <FieldLabel>Address</FieldLabel>
        </Col>
      </Row>

      <Row style={s('mb-1')}>
        <Col span={24}>
          <Value>{formatAddress(property?.address)}</Value>
        </Col>
      </Row>

      <Row style={s('mb-1')}>
        <Col span={12}>
          <FieldLabel>Estimated Value</FieldLabel>
        </Col>

        <Col span={12}>
          <FieldLabel>Allocated Loan</FieldLabel>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Value>{formatCurrency(property?.valuation?.value)}</Value>
        </Col>

        <Col span={12}>
          <Value>{formatPercentage(property?.securityPercentage)}</Value>
        </Col>
      </Row>
    </div>
  )
}

export const ExpandedPropertyBox = ({ properties = [], onClick }) => {
  return (
    <CollapsableBox>
      {properties.map((property, index) => {
        const isLastItem = properties.length === index + 1

        return (
          <PropertyItem
            property={property}
            listOrder={index + 1}
            key={property?._id}
            style={isLastItem ? s('mb-0') : s('mb-6')}
          />
        )
      })}
      <CollapseButton onClick={onClick}>
        <Row style={s('justify-center')}>
          <div>Collapse</div>
          <ChevronUpIcon width={20} height={20} />
        </Row>
      </CollapseButton>
    </CollapsableBox>
  )
}
