import { prop } from '@neo/ramda-extra'
import { reconciledPaymentsAdapter } from './module'
import { createSelector } from '@reduxjs/toolkit'

const selectReconciledPayments = prop('reconciledPayments')

export const selectTotal = createSelector(selectReconciledPayments, prop('total'))

export const {
  selectAll: selectAllReconciledPayments,
} = reconciledPaymentsAdapter.getSelectors(selectReconciledPayments)
