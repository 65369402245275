import React, { useEffect, useState } from 'react'
import { useParams, Switch, Route } from 'react-router-dom'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { useDispatch, useSelector } from 'react-redux'
import { styled, s } from '@vega/styled/v2'
import { Container as ContainerBase, Row, Col } from '@vega/styled'
import { selectIsFetchCustomerLoansPending, fetchCustomerLoans } from 'modules/customer'
import { fetchInvitation, selectInvitationByEmail } from 'modules/entityInvitation'
import {
  selectLegalEntityById,
  selectIsFetchingLegalEntity,
  fetchLegalEntity,
} from 'modules/legalEntity'
import { routes } from 'routes'
import { PersonalDetails } from './PersonalDetails'
import { PaymentDetailsView } from './PaymentDetails'
import CustomerDetailNavigation from './CustomerDetailNavigation'
import { LoanAccountsView } from './LoanAccounts'
import { AccessInvitationModal } from './AccessInvitationModal'
import { Header } from './Header'
import { INVITATION_STATUSES } from '@vega/constants'

const customerRoutes = routes.admin.customers

const Container = styled(ContainerBase)(s('h-full mx-0 pt-4', { overflowY: 'auto' }))

const Aside = styled.aside(s('relative min-w-14'))

function getCustomerName(customer) {
  return customer?.name || 'customer'
}

function getAccessModalData(customer) {
  return {
    id: customer?.id,
    email: customer?.email,
    name: getCustomerName(customer),
  }
}

// eslint-disable-next-line complexity
function Details() {
  const dispatch = useDispatch()
  const { id } = useParams()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const customer = useSelector((state) => selectLegalEntityById(state, id))
  const invitation = useSelector(selectInvitationByEmail(customer?.email))

  const isFetchingLegalEntity = useSelector(selectIsFetchingLegalEntity)
  const isFetchCustomerLoansPending = useSelector(selectIsFetchCustomerLoansPending)

  useEffect(() => {
    dispatch(fetchLegalEntity(id))
    dispatch(fetchCustomerLoans({ customerId: id }))
  }, [dispatch, id])

  useEffect(() => {
    if (isNilOrEmpty(customer)) return

    dispatch(fetchInvitation(customer?.email))
  }, [dispatch, customer, isModalOpen])

  return (
    <Container fluid>
      <Header
        customerName={getCustomerName(customer)}
        shouldDisplayInvitationBtn={isNilOrEmpty(invitation)}
        shouldDisplayReInviteBtn={
          !isNilOrEmpty(invitation) &&
          invitation.status !== INVITATION_STATUSES.ACCEPTED
        }
        openModal={() => setIsModalOpen(true)}
      />

      <Row justify="center">
        <Col span={16} style={s('flex')}>
          <Aside style={s('mr-4')}>
            <CustomerDetailNavigation />
          </Aside>

          <Switch>
            <Route
              exact
              path={customerRoutes.customerDetails}
              render={() => (
                <PersonalDetails
                  isLoading={isFetchingLegalEntity}
                  customer={customer}
                  invitation={invitation}
                />
              )}
            />
            <Route
              path={customerRoutes.paymentDetails}
              render={() => <PaymentDetailsView />}
            />
            <Route
              path={customerRoutes.loans}
              render={() => (
                <LoanAccountsView isLoading={isFetchCustomerLoansPending} />
              )}
            />
          </Switch>
        </Col>
      </Row>

      <AccessInvitationModal
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        customer={getAccessModalData(customer)}
        invitationCode={invitation?.invitationCode}
      />
    </Container>
  )
}
export default Details
