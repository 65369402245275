import React from 'react'

import { Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { pipe } from '@neo/ramda-extra'
import userGroup from '@vega/components/src/assets/images/user-group.svg'
import clipBoard from '@vega/components/src/assets/images/clipboard.svg'
import { ReactComponent as DownArrow } from '@vega/components/src/assets/images/stockArrowDown.svg'
import { ReactComponent as UpArrow } from '@vega/components/src/assets/images/stockArrowTop.svg'
import { calculatePrevMonth } from 'features/admin/homePage/utils/calculatePrevMonth'

const Root = styled.div(s('flex flex-column flex-1 mx-8'))
const Column = styled.div(s('flex flex-column'))
const Title = styled.h3(s('p-0 m-0 font-semibold text-xl mt-0 ml-0 text-primary'))
const SubTitle = styled.h3(s('p-0 m-0 font-normal text-lg text-grey-600'))
const PercentageChange = styled.span(s('text-xl text-error-700 mr-1'))
const Month = styled.span(s('text-grey-700 font-semibold'))
const OverviewContainer = styled.div(
  s('bg-white rounded-lg px-4', {
    boxShadow:
      '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
    width: '100%',
    height: '100%',
  })
)
const Img = styled.img(
  s('ml-a mr-a block w-1/2 h-1/2 self-center ', {
    display: 'block',
  })
)
const CustomerAndMortgageContainer = styled.div(
  s('flex justify-between bg-grey-lightest rounded-lg p-3 mt-4 w-full', {
    '@media (max-width: 1500px)': s('flex-column'),
  })
)

const DisplayContainer = styled.div(
  s('bg-white mx-2 w-full rounded-lg p-4', {
    height: 116,
  })
)

const getChangesStatus = (changes) => {
  if (changes === 'N/A') return 'notApplicable'
  if (changes > 0) return 'positive'
  if (changes < 0) return 'negative'
  return 'unchanged'
}
const CircleContainer = styled.div(
  s('bg-secondary justify-center flex', {
    width: 36,
    height: 36,
    borderRadius: 50,
  })
)

const changesValueStylesByStatus = {
  positive: { marginRight: 1, color: '#009F80' },
  negative: s('text-accent-red', { marginRight: 1 }),
  unchanged: s('text-grey-500'),
  notApplicable: s('text-grey-500'),
}

const arrowIconByChangesStatus = {
  positive: <UpArrow style={{ color: '#009F80' }} />,
  negative: <DownArrow style={s('text-accent-red mr-2')} />,
}

const monthNameByNumber = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

const defaultNaNToZero = (v) => {
  if (Number.isNaN(v) || v === 'NaN') return 0
  return v
}

const defaultInfiniteValueToInapplicable = (v) => {
  if (v === Infinity) return 'N/A'
  return v
}

const defaultNaNToDash = (v) => {
  if (Number.isNaN(v) || v === 'NaN' || v === undefined) return '-'

  return `${v}`
}

function Overview({
  averageLoanSize,
  totalClients,
  customerPercentageChanges,
  mortgagePercentageChanges,
  year,
  month,
}) {
  const formattedCustomerPercentageChanges = pipe(
    defaultNaNToZero,
    defaultInfiniteValueToInapplicable
  )(customerPercentageChanges)

  const formattedMortgagePercentageChanges = pipe(
    defaultNaNToZero,
    defaultInfiniteValueToInapplicable
  )(mortgagePercentageChanges)

  const showChangesPercentSign =
    (formattedCustomerPercentageChanges && formattedMortgagePercentageChanges) !== 'N/A'

  const customerChangesStatus = getChangesStatus(formattedCustomerPercentageChanges)

  const mortgageChangesStatus = getChangesStatus(formattedMortgagePercentageChanges)

  const { prevMonth } = calculatePrevMonth(year, month)

  return (
    <Root>
      <OverviewContainer>
        <Row style={s('items-center my-6 mx-0 justify-start')}>
          <Row style={s('items-center mx-1 justify-start')}>
            <Title className="sohne" style={s('text-primary')}>
              Overview
            </Title>
          </Row>
          <CustomerAndMortgageContainer>
            <DisplayContainer
              style={s('bg-white rounded-lg p-4', {
                boxShadow:
                  '0px 2px 4px -1px rgba(21, 61, 58, 0.06), 0px 4px 6px -1px rgba(21, 61, 58, 0.1)',
              })}
            >
              <Row style={s('items-start justify-between')}>
                <Row style={s('mx-2')}>
                  <CircleContainer>
                    <Img src={userGroup} />
                  </CircleContainer>

                  <Col style={s('items-start mx-2 justify-start')}>
                    <SubTitle>Total client</SubTitle>
                    <Title className="sohne" style={s('text-4xl')}>
                      {defaultNaNToDash(totalClients)}
                    </Title>
                  </Col>
                </Row>

                <Column style={s('items-end mr-4')}>
                  <Row style={s('justify-end items-center')}>
                    <PercentageChange
                      style={s(
                        'mb-1',
                        changesValueStylesByStatus[customerChangesStatus]
                      )}
                    >
                      {formattedCustomerPercentageChanges}
                      {showChangesPercentSign && '%'}
                    </PercentageChange>
                    {arrowIconByChangesStatus[customerChangesStatus]}
                  </Row>
                  <span style={s('text-grey-500', { fontSize: 10 })}>
                    Compared to <Month>{monthNameByNumber[prevMonth]}</Month>
                  </span>
                </Column>
              </Row>
            </DisplayContainer>

            <DisplayContainer style={s('bg-grey-lightest')}>
              <Row style={s('items-start justify-between')}>
                <Row style={s('mx-2')}>
                  <CircleContainer style={s('bg-grey-200')}>
                    <Img src={clipBoard} style={s('self-center')} />
                  </CircleContainer>

                  <Col style={s('items-start mx-2 justify-between')}>
                    <SubTitle>Average Loan size</SubTitle>
                    <Title className="sohne" style={s('text-4xl')}>
                      ${defaultNaNToDash(averageLoanSize)}
                    </Title>
                  </Col>
                </Row>
                <Column style={s('items-end mr-4')}>
                  <Row style={s('justify-end items-center')}>
                    <PercentageChange
                      style={s(
                        'mb-1',
                        changesValueStylesByStatus[mortgageChangesStatus]
                      )}
                    >
                      {formattedMortgagePercentageChanges}
                      {showChangesPercentSign && '%'}
                    </PercentageChange>
                    {arrowIconByChangesStatus[mortgageChangesStatus]}
                  </Row>
                  <span style={s('text-grey-500', { fontSize: 10 })}>
                    Compared to <Month>{monthNameByNumber[prevMonth]}</Month>
                  </span>
                </Column>
              </Row>
            </DisplayContainer>
          </CustomerAndMortgageContainer>
        </Row>
      </OverviewContainer>
    </Root>
  )
}

export default Overview
