import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Container } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
  Divider as DividerBase,
} from '@vega/components/src/v2'
import { isNotNilOrEmpty } from '@neo/ramda-extra'
import { fetchInvitation } from 'modules/entityInvitation'
import { INVITATION_STATUSES, TEST_IDS } from '@vega/constants'
import formatters from 'utils/formatters'
import {
  isGuarantorEntity,
  isPrimaryEntity,
  hasOnlineAccess,
  AccessedPill,
  PrimaryPill,
  InvitedPill,
  GuarantorPill,
} from '../common'
import { routes } from 'routes'
import { useHistory } from 'react-router-dom'

const ContentCard = styled(ContentCardBase)(s('mb-9 p-5'))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const Divider = styled(DividerBase)(s('my-5'))
const Heading = styled.p(
  s('m-0 text-primary font-semibold text-sm', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const ViewButton = styled.div(s('text-primary font-semibold text-sm mr-3'), {
  cursor: 'pointer',
})

export const TrustsDetails = ({ trusts = [], loan = {} }) => {
  const dispatch = useDispatch()
  const [invitations, setInvitations] = useState([])

  const history = useHistory()

  const insertId = (url = '', id) => url.replace(':id', id)

  const viewTrustDetails = (id) => {
    history.push(insertId(routes.admin.customers.customerDetails, id), { id })
  }

  useEffect(() => {
    async function fetchInvitations() {
      const trustsEmail = trusts.map((trust) => trust?.email)
      const invitations = await Promise.allSettled(
        trustsEmail.map((email) => dispatch(fetchInvitation(email)))
      )
      setInvitations(
        invitations.map((invitation) => invitation?.value?.payload?.items?.[0])
      )
    }

    fetchInvitations()
  }, [dispatch, trusts])

  return (
    <Container style={s('px-5')}>
      <Row>
        <Col span={24}>
          <SectionTitle title="Contact Details" />
          <ContentCard>
            {trusts.length === 0 ? (
              <div
                style={s('flex items-center justify-center text-grey-700 py-16 px-32')}
              >
                No trust(s) contact details for this mortgage
              </div>
            ) : (
              // eslint-disable-next-line complexity
              trusts.map((trust, index, { length }) => {
                const isLastTrust = length === index + 1

                const invitation = invitations?.find(
                  (inv) => inv?.email === trust?.email
                )

                const isInvitationPending =
                  isNotNilOrEmpty(invitation) &&
                  invitation?.status !== INVITATION_STATUSES.ACCEPTED

                return (
                  <>
                    <Row key={index}>
                      <Col span={24}>
                        <div style={s('flex justify-between')}>
                          <div style={s('flex justify-start')}>
                            <Heading style={{ padding: '0 10px 0 0' }}>
                              TRUST {index + 1}
                            </Heading>
                            {isInvitationPending && (
                              <InvitedPill>INVITE PENDING</InvitedPill>
                            )}

                            {!hasOnlineAccess(invitation, trust) && (
                              <AccessedPill>NO ONLINE ACCESS</AccessedPill>
                            )}

                            {isPrimaryEntity(loan, trust?.id) && (
                              <PrimaryPill>PRIMARY</PrimaryPill>
                            )}
                            {isGuarantorEntity(loan, trust?.id) && (
                              <GuarantorPill>GUARANTOR</GuarantorPill>
                            )}
                          </div>
                          <div style={s('flex justify-end')}>
                            <ViewButton
                              data-test-id={TEST_IDS.ENTITIY_DETAIL_VIEW_BTN}
                              onClick={() => viewTrustDetails(trust?.id)}
                            >
                              View
                            </ViewButton>
                          </div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <DataDisplay label="Trust Name" value={trust.name} />
                      </Col>

                      <Col span={12}>
                        <DataDisplay
                          label="Email"
                          value={trust.email}
                          valueStyle={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        />
                      </Col>

                      <Col span={12}>
                        <DataDisplay
                          label="Type"
                          value={formatters.capitaliseFirstLetter(trust.role)}
                        />
                      </Col>

                      <Col span={12}>
                        <DataDisplay
                          label="Mobile Number"
                          value={formatters.formatPhoneNumber(trust?.phoneNumber)}
                        />
                      </Col>

                      <Col span={24}>
                        <DataDisplay
                          label="Customer Number"
                          value={trust?.customerNumber}
                        />
                      </Col>

                      <Col span={24}>
                        <DataDisplay
                          label="Address"
                          value={trust?.addresses[0]?.fullAddress}
                        />
                      </Col>
                    </Row>
                    {!isLastTrust && <Divider />}
                  </>
                )
              })
            )}
          </ContentCard>
        </Col>
      </Row>
    </Container>
  )
}
