/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '@vega/components/src/v2'
import { DottedCircleLoader, toast, Button, Modal } from '@vega/components'
import { isNilOrEmpty, isNotNilOrEmpty } from '@neo/ramda-extra'
import { useParams, useHistory } from 'react-router-dom'
import { ReactComponent as CloseButtonBase } from '@vega/components/src/assets/images/x.svg'
import { fetchCustomerLoans } from 'modules/customer'
import {
  selectLegalEntityById,
  fetchLegalEntity,
  selectIsFetchingLegalEntity,
} from 'modules/legalEntity'
import {
  createInvitation,
  fetchInvitation,
  selectInvitationByEmail,
} from 'modules/entityInvitation'
import { INVITATION_STATUSES, TEST_IDS } from '@vega/constants'
import {
  isPrimaryEntity,
  hasOnlineAccess,
  AccessedPill,
  PrimaryPill,
  InvitedPill,
} from '../common'
import formatters from 'utils/formatters'
import { routes } from 'routes'

const Message = styled.div(s('text-start text-grey-600 text-sm mb-4'))
const CloseButton = styled(CloseButtonBase)(s('text-grey-700', { cursor: 'pointer' }))
const ButtonContainer = styled.div(s('flex justify-around'))
const SendInvitationContainer = styled.div(
  s('p-6 rounded-lg bg-white ', {
    borderRadius: 12,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    maxWidth: 490,
  })
)

const ContentCard = styled(ContentCardBase)(s('mb-9 p-5'))
const SectionTitle = styled(SectionTitleBase)(s('mb-4'))
const LoaderContainer = styled.div(s('flex justify-center items-center h-8'))

const Heading = styled.p(
  s('m-0 text-primary font-semibold text-sm', {
    letterSpacing: '0.015em',
    lineHeight: 1.5,
  })
)

const ViewButton = styled.div(s('text-primary font-semibold text-sm mr-3'), {
  cursor: 'pointer',
})

// eslint-disable-next-line complexity
export const IndividualDetails = ({ loan }) => {
  const history = useHistory()
  const { entityId } = useParams()

  const insertId = (url = '') => url.replace(':id', entityId)

  const dispatch = useDispatch()

  const viewCustomerDetails = () => {
    history.push(insertId(routes.admin.customers.customerDetails), { entityId })
  }

  const [isSendingInvitationOpened, setIsSendingInvitationOpened] = useState(false)

  const isFetchingIndividual = useSelector(selectIsFetchingLegalEntity)
  const individual =
    useSelector((state) => selectLegalEntityById(state, entityId)) || {}
  const invitation = useSelector(selectInvitationByEmail(individual?.email))

  const isInvitationPending =
    isNotNilOrEmpty(invitation) && invitation.status !== INVITATION_STATUSES.ACCEPTED

  async function handleSendInvitation(email) {
    if (isNilOrEmpty(email)) {
      toast('Need valid email for invitation sending')
      return
    }
    setIsSendingInvitationOpened(false)

    await dispatch(createInvitation({ email, role: 'vg_banking_client' }))
    dispatch(fetchInvitation(email))
  }

  useEffect(() => {
    dispatch(fetchLegalEntity(entityId))
    dispatch(fetchCustomerLoans({ customerId: entityId }))
  }, [dispatch, entityId])

  useEffect(() => {
    if (isNotNilOrEmpty(individual?.email)) {
      dispatch(fetchInvitation(individual?.email))
    }
  }, [dispatch, individual])

  if (isFetchingIndividual) {
    return (
      <Container>
        <Row>
          <Col span={24}>
            <SectionTitle title="Personal Details" />
            <ContentCard>
              <LoaderContainer>
                <DottedCircleLoader size="md" />
              </LoaderContainer>
            </ContentCard>
          </Col>

          <Col span={24}>
            <SectionTitle title="Contact Details" />
            <ContentCard>
              <LoaderContainer>
                <DottedCircleLoader size="md" />
              </LoaderContainer>
            </ContentCard>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container
      style={{
        marginTop: '-9%',
      }}
    >
      <Row
        style={s('flex-1 ', {
          marginBottom: 73,
        })}
      >
        <div />
      </Row>

      <Row style={s('mt-6')}>
        <Col span={24}>
          <SectionTitle title="Personal Details" />
          <ContentCard>
            <Row>
              <Col span={24}>
                <div style={s('flex justify-between')}>
                  <div style={s('flex justify-start')}>
                    <DataDisplay
                      label="Legal Name"
                      value={individual?.name}
                      style={{ padding: '0 10px 0 0' }}
                    />

                    {isInvitationPending && <InvitedPill>INVITE PENDING</InvitedPill>}

                    {!hasOnlineAccess(invitation, individual) && (
                      <AccessedPill>NO ONLINE ACCESS</AccessedPill>
                    )}

                    {isPrimaryEntity(loan, entityId) && (
                      <PrimaryPill>PRIMARY</PrimaryPill>
                    )}
                  </div>
                  <div style={s('flex justify-end')}>
                    <ViewButton
                      data-test-id={TEST_IDS.ENTITIY_DETAIL_VIEW_BTN}
                      onClick={viewCustomerDetails}
                    >
                      View
                    </ViewButton>
                  </div>
                </div>
              </Col>

              <Col span={24}>
                <DataDisplay
                  testId={TEST_IDS.ENTITIY_CUSTOMER_NO}
                  label="Cust No."
                  value={individual?.customerNumber}
                />
              </Col>
            </Row>
          </ContentCard>
        </Col>

        <Col span={24}>
          <SectionTitle title="Contact Details" />
          <ContentCard>
            <Row>
              <Col span={24}>
                <Heading>PERSONAL</Heading>
              </Col>

              <Col span={24}>
                <DataDisplay label="Email Address" value={individual?.email} />
              </Col>

              <Col span={24}>
                <DataDisplay
                  label="Mobile Phone Number"
                  value={formatters.formatPhoneNumber(individual?.phoneNumber)}
                />
              </Col>

              <Col span={24}>
                <DataDisplay
                  label="Address"
                  value={formatters.buildAddress(
                    {
                      streetName: individual?.addresses?.[0]?.streetName,
                      streetNumber: individual?.addresses?.[0]?.streetNumber,
                      streetType: individual?.addresses?.[0]?.streetType,
                    },
                    individual?.addresses?.[0]?.suburbName,
                    individual?.addresses?.[0]?.territorialAuthorityName,
                    individual?.addresses?.[0]?.countryISO,
                    individual?.addresses?.[0]?.streetUnitNumber,
                    individual?.addresses?.[0]?.postCode,
                    individual?.addresses?.[0]?.fullAddress
                  )}
                />
              </Col>
            </Row>
          </ContentCard>
        </Col>
      </Row>

      <Modal isOpen={isSendingInvitationOpened}>
        <SendInvitationContainer>
          <Row style={s('justify-between')}>
            <Message>
              <span>
                <strong>Access Invitation</strong>
              </span>
            </Message>
            <CloseButton onClick={() => setIsSendingInvitationOpened(false)} />
          </Row>

          <Message>
            <span>
              Do you want to send access invitation to{' '}
              <strong>{individual?.name || '-'} </strong>
              for this loan?
            </span>
          </Message>

          <Row style={s('mt-9')}>
            <Col span={24} lg={12}>
              <ButtonContainer>
                <Button
                  onClick={() => setIsSendingInvitationOpened(false)}
                  style={s('bg-white')}
                >
                  Cancel
                </Button>
              </ButtonContainer>
            </Col>

            <Col span={24} lg={12}>
              <ButtonContainer>
                <Button
                  onClick={() => handleSendInvitation(individual?.email)}
                  style={s('w-full')}
                >
                  Send Access Invitation
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
        </SendInvitationContainer>
      </Modal>
    </Container>
  )
}
