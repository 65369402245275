/* eslint-disable prettier/prettier */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { useCallback } from 'react'
import { s, styled } from '@vega/styled/v2'
import { formatDateString } from 'utils/date'
import { PRODUCT_CONSTANTS } from '@vega/constants'

import { Button as ButtonBase, toast } from '@vega/components'
import { routes } from 'routes'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { archiveProduct, restoreProduct } from 'modules/product'

const { PRODUCT_STATUS, INTEREST_TYPE } = PRODUCT_CONSTANTS

const PRODUCT_INTEREST_TYPE_DISPLAY_NAME = {
  [INTEREST_TYPE.FIXED]: 'Fixed',
  [INTEREST_TYPE.VARIABLE]: 'Variable',
  [INTEREST_TYPE.COMBO]: 'Combo',
}

// Disclaimer: product constants were not available in the vega/constants package
// const PRODUCT_TYPE_DISPLAY_NAME = {
//   'interest_only': 'Interest only',
//   'principal_and_interest': 'Principal and interest',
//   'interest_in_advance': 'Interest in advance',
//   'line_of_credit': 'Line of credit',
// }

const PRODUCT_STATUS_DISPLAY_NAME = {
  [PRODUCT_STATUS.ACTIVE]: 'Active',
  [PRODUCT_STATUS.ARCHIVED]: 'Archived',
}

const ActionsContainer = styled.div(s('flex', { gap: 12 }))

const ActionButton = styled(ButtonBase)(
  s(
    'bg-white border-solid border-1 border-grey-300 text-xs font-semibold w-4 rounded-lg'
  )
)

const StatusPill = styled.div(
  s('rounded-2xl px-3 py-1 h-2 flex items-center justify-center'),
  ({ status }) =>
    status === PRODUCT_STATUS.ACTIVE ? s('bg-green-100') : s('bg-grey-100')
)


const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

const headerStyleBase = (customStyles) =>
  s('px-4 flex flex-row  items-center text-left text-xs font-semibold text-grey-600', {
    letterSpacing: 0,
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('px-4 inline-block text-sm text-left flex items-center font-semibold', {
    ...textEllipsis,
    ...customStyles,
  })

export const Columns = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleEdit = useCallback(
    (productId) => {
      history.push(`${routes.admin.products.root}/${productId}`)
    },
    [history]
  )

  const handleArchive = useCallback(
    async (productId) => {
      const { error } = await dispatch(archiveProduct(productId))

      if (!error) {
        toast(({ toastProps: { toastId } }) => (
          <div>
            Product archived.
            <span
              style={s('ml-4 text-primary font-bold')}
              onMouseDown={() => handleUndo(toastId)}
            >
              Undo
            </span>
          </div>
        ))
      }

      async function handleUndo(toastId) {
        const { error } = await dispatch(restoreProduct(productId))

        if (error) {
          toast.update(toastId, { render: 'Product restore failed!', autoClose: 2000 })
          return
        }

        toast.update(toastId, { render: 'Product restored!', autoClose: 2000 })
      }
    },
    [dispatch]
  )

  return React.useMemo(
    () => [
      {
        Header: 'Created date',
        accessor: (row) => formatDateString(row.createdAt),
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
      },
      {
        Header: 'Product name',
        accessor: 'name',
        tableHeaderStyles: headerStyleBase({ width: '16%' }),
        tableCellStyles: cellStyleBase(s('font-normal', { width: '16%' })),
      },
      {
        Header: 'Start date',
        accessor: (row) => formatDateString(row.fields.validFrom),
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
      },
      {
        Header: 'Product code',
        accessor: 'code',
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
      },
      {
        Header: 'Product type',
        // accessor: (row) => PRODUCT_TYPE_DISPLAY_NAME[row.fields.ongoingRepaymentType],
        tableHeaderStyles: headerStyleBase({ width: '16%' }),
        tableCellStyles: cellStyleBase({ width: '16%' }),
      },
      {
        Header: 'Interest type',
        accessor: (row) =>
          PRODUCT_INTEREST_TYPE_DISPLAY_NAME[row.fields.initialRateType],
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
      },
      {
        Header: 'Interest rate',
        accessor: (row) => `${String(row.fields.initialRate)}%`,
        tableHeaderStyles: headerStyleBase({ width: '8%' }),
        tableCellStyles: cellStyleBase({ width: '8%' }),
      },
      {
        Header: 'Status',
        accessor: 'status',
        tableHeaderStyles: headerStyleBase({ width: '10%' }),
        tableCellStyles: cellStyleBase({ width: '10%' }),
        Cell: ({ value: status }) => {
          return (
            <StatusPill status={status}>
              {PRODUCT_STATUS_DISPLAY_NAME[status]}
            </StatusPill>
          )
        },
      },
      {
        accessor: 'id',
        disableSortBy: true,
        tableHeaderStyles: { width: '16%' },
        tableCellStyles: cellStyleBase({ width: '16%' }),
        Cell: ({
          value: productId,
          row: {
            original: { status },
          },
        }) =>
          status === PRODUCT_STATUS.ARCHIVED ? null : (
            <ActionsContainer>
              <ActionButton onClick={() => handleEdit(productId)}>Edit</ActionButton>

              <ActionButton onClick={() => handleArchive(productId)}>
                Archive
              </ActionButton>
            </ActionsContainer>
          ),
      },
    ],
    [handleArchive, handleEdit]
  )
}

export const Rows = ({ products }) => {
  return React.useMemo(
    () =>
      products.map((product) => {
        const customRowStyle =
          product?.status === PRODUCT_STATUS.ARCHIVED
            ? s('text-grey-light', {
                ' > td:not(:last-child):not(:nth-of-type(4)):not(:nth-of-type(8))': {
                  opacity: 0.5,
                },
              })
            : undefined
        return { ...product, customRowStyle }
      }),
    [products]
  )
}
