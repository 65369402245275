import { createSelector } from '@reduxjs/toolkit'
import { prop } from '@neo/ramda-extra'
import { accountsAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

export const {
  selectEntities: selectAccountEntities,
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
} = accountsAdapter.getSelectors(prop('accounts'))

export const {
  selectIsPending: selectIsFetchAccountsPending,
} = createAsyncThunkSelectors('accounts/fetchAccounts')

export const {
  selectIsPending: selectIsFetchAccountPending,
} = createAsyncThunkSelectors('accounts/fetchAccount')

const selectAccountsState = prop('accounts')

export const selectInspectedAccountId = createSelector(
  selectAccountsState,
  prop('inspectedAccountId')
)
