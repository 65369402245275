import React from 'react'
import { Col, Row, useLargeMediaQuery } from '@vega/styled'
import { DataDisplay, DateBox as DueDateBox } from '@vega/components/src/v2'
import { s, styled } from '@vega/styled/v2'
import { TRANSACTION_STATUS, REPAYMENT_TYPE_DISPLAY_TEXT } from '@vega/constants'
import { formatCurrency } from 'utils/formatter'

const getRepaymentTypeDisplay = (repaymentType) => {
  return REPAYMENT_TYPE_DISPLAY_TEXT[repaymentType]
}

// eslint-disable-next-line complexity
function PaymentCard({ loan, payment }) {
  const { displayName, repaymentType } = loan?.product || {}
  const { dueDate, amount, status } = payment || {}
  const loanTermInYears = loan?.loanTermInMonths / 12 || '-'
  const isLargeScreen = useLargeMediaQuery('larger')
  const StatusPill = styled.div(
    s(' rounded-2xl text-md px-3  h-2 flex items-center justify-center'),
    {
      fontFamily: 'Poppins',
    },
    ({ status }) =>
      status === TRANSACTION_STATUS.PAID ? s('bg-secondary') : s('bg-accent-red')
  )

  return (
    <div>
      <Row style={s('pt-4 pb-4 pl-2 pr-2')} gutter={[0, 0]}>
        <Col span={0} lg={4}>
          <DueDateBox date={new Date(dueDate)} />
        </Col>

        <Col span={19} lg={14}>
          <DataDisplay
            label={`${
              displayName || '-'
            } · ${loanTermInYears} years · ${getRepaymentTypeDisplay(repaymentType)}`}
            value={loan?.loanNumber}
            labelStyle={s('text-black font-semibold')}
            valueStyle={s('text-grey-600 text-sm font-normal')}
          />
        </Col>
        <Col span={0} lg={2}>
          <StatusPill status={status}>{status.toUpperCase()}</StatusPill>
        </Col>
        <Col span={5} lg={4}>
          <div style={s('text-right ml-1')}>{formatCurrency(amount, 2)}</div>
        </Col>

        <Col
          push={isLargeScreen ? 0 : 0}
          span={5}
          lg={0}
          xl={0}
          jb={0}
          style={s('ml-0 ')}
        >
          <div style={s('flex mt-4 flex-row justify-start')}>
            <StatusPill status={status}>{status?.toUpperCase() || '-'}</StatusPill>
          </div>
        </Col>

        <Col
          push={isLargeScreen ? 0 : 15}
          span={4}
          lg={0}
          xl={0}
          jb={0}
          style={s('ml-0 ')}
        >
          <div style={s('flex mt-2 flex-row justify-end')}>
            <DueDateBox date={new Date(dueDate)} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PaymentCard
