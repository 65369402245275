import React from 'react'
import { isNilOrEmpty, noop } from '@neo/ramda-extra'
import { useDispatch } from 'react-redux'

import { styled, s } from '@vega/styled/v2'
import { Row, Col } from '@vega/styled'
import { toast, Modal, Button } from '@vega/components'
import { ReactComponent as CloseButtonBase } from '@vega/components/src/assets/images/x.svg'

import { fetchCustomerLoans } from 'modules/customer'
import {
  createInvitation,
  fetchInvitation,
  resendInvitation,
} from 'modules/entityInvitation'
import { fetchLegalEntity } from 'modules/legalEntity'

const Message = styled.div(s('text-start text-grey-600 text-sm mb-4'))
const ButtonContainer = styled.div(s('flex justify-around'))
const SendInvitationContainer = styled.div(
  s('p-6 rounded-lg bg-white ', {
    borderRadius: 12,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    maxWidth: 490,
  })
)

const CloseButton = styled(CloseButtonBase)(s('text-grey-700', { cursor: 'pointer' }))

export const AccessInvitationModal = ({
  closeModal = noop,
  isOpen = false,
  customer,
  invitationCode = '',
}) => {
  const dispatch = useDispatch()
  const { id, name, email } = customer

  async function handleSendInvitation() {
    if (isNilOrEmpty(customer) || isNilOrEmpty(email)) {
      toast('Need valid email for invitation sending')
      return
    }

    await Promise.all([
      dispatch(fetchLegalEntity(id)),
      dispatch(fetchCustomerLoans({ customerId: id })),
      dispatch(createInvitation({ email, role: 'vg_banking_client' })),
      dispatch(fetchInvitation(email)),
    ])
    closeModal()
  }

  async function handleResendInvitation(invitationCode) {
    if (isNilOrEmpty(invitationCode)) {
      toast('Need valid invitation reference for invitation resending')
      return
    }

    await Promise.all([dispatch(resendInvitation(invitationCode))])

    closeModal()
  }

  return (
    <Modal isOpen={isOpen}>
      <SendInvitationContainer>
        <Row style={s('justify-between')}>
          <Message>
            <span>
              <strong>Access Invitation</strong>
            </span>
          </Message>
          <CloseButton onClick={closeModal} />
        </Row>

        <Message>
          <span>
            Do you want to send access invitation to <strong>{name} </strong>
            for this loan?
          </span>
        </Message>
        <Row style={s('mt-9')}>
          <Col span={24} lg={12}>
            <ButtonContainer>
              <Button onClick={closeModal} style={s('bg-white')}>
                Cancel
              </Button>
            </ButtonContainer>
          </Col>
          <Col span={24} lg={12}>
            <ButtonContainer>
              {!invitationCode ? (
                <Button onClick={() => handleSendInvitation()} style={s('w-full')}>
                  Send Access Invitation
                </Button>
              ) : null}
              {invitationCode ? (
                <Button
                  onClick={() => handleResendInvitation(invitationCode)}
                  style={s('w-full')}
                >
                  Resend Access Invitation
                </Button>
              ) : null}
            </ButtonContainer>
          </Col>
        </Row>
      </SendInvitationContainer>
    </Modal>
  )
}
