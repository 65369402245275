/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'

import { s, styled } from '@vega/styled/v2'
import { formatDateString } from 'utils/date'
import { isNil, path } from '@neo/ramda-extra'
import { differenceInDays } from 'date-fns'
import { Tooltip } from '@mui/material'
import { Button } from '@vega/components'

const NotDueYetPill = styled.div(
  s('rounded-2xl px-3 py-1 h-2 flex items-center justify-center bg-grey-100')
)

const DueNowPill = styled.div(
  s(
    'rounded-2xl px-3 py-1 h-2 flex items-center justify-center text-green-800 bg-green-100'
  )
)

const OverduePill = styled.div(
  s(
    'rounded-2xl px-3 py-1 h-2 flex items-center justify-center text-white bg-accent-red mr-1'
  )
)

const headerStyleBase = (customStyles) =>
  s('px-4 inline-block text-grey-600 text-left text-xs font-semibold', {
    ...customStyles,
  })

const cellStyleBase = (customStyles) =>
  s('px-4 inline-block text-grey-800 text-sm text-left flex items-center', {
    ...customStyles,
  })

const TextOverflowEllipsis = styled.span({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const isInDaysRange = (value, start, end) => {
  return value >= start && value < end
}

const getDaysOverdueByLabel = (diffInDays, status) => {
  if (diffInDays >= 0 || status === 'paid') return '-'

  const absoluteDiffInDays = Math.abs(diffInDays)

  switch (true) {
    case absoluteDiffInDays < 30:
      return (
        <Tooltip title="< 30 days">
          <OverduePill>
            <TextOverflowEllipsis>&lt; 30 days</TextOverflowEllipsis>
          </OverduePill>
        </Tooltip>
      )
    case isInDaysRange(absoluteDiffInDays, 30, 60):
      return (
        <Tooltip title="30 - 60 days">
          <OverduePill>
            <TextOverflowEllipsis>30 - 60 days</TextOverflowEllipsis>
          </OverduePill>
        </Tooltip>
      )
    case isInDaysRange(absoluteDiffInDays, 60, 90):
      return (
        <Tooltip title="60 - 90 days">
          <OverduePill>
            <TextOverflowEllipsis>60 - 90 days</TextOverflowEllipsis>
          </OverduePill>
        </Tooltip>
      )
    default:
      return (
        <Tooltip title="> 90 days">
          <OverduePill>
            <TextOverflowEllipsis>&gt; 90 days</TextOverflowEllipsis>
          </OverduePill>
        </Tooltip>
      )
  }
}

const getPaymentStatusLabel = (
  diffInDays,
  status,
  paymentId,
  overDueEmailSent,
  sendOverdueEmail
) => {
  switch (true) {
    case status === 'paid':
      return (
        <Tooltip title="Paid">
          <NotDueYetPill>
            <TextOverflowEllipsis>Paid</TextOverflowEllipsis>
          </NotDueYetPill>
        </Tooltip>
      )
    case diffInDays === 0:
      return (
        <Tooltip title="Due today">
          <DueNowPill>
            <TextOverflowEllipsis>Due today</TextOverflowEllipsis>
          </DueNowPill>
        </Tooltip>
      )
    case diffInDays < 0:
      return (
        <>
          <Tooltip title="Overdue">
            <OverduePill>
              <TextOverflowEllipsis>Overdue</TextOverflowEllipsis>
            </OverduePill>
          </Tooltip>
          {!overDueEmailSent ? (
            <Button onClick={() => sendOverdueEmail(paymentId)}>Send Email</Button>
          ) : (
            <Button onClick={() => sendOverdueEmail(paymentId)}>Resend Email</Button>
          )}
        </>
      )
    default:
      return (
        <Tooltip title="Not due yet">
          <NotDueYetPill>
            <TextOverflowEllipsis>Not due yet</TextOverflowEllipsis>
          </NotDueYetPill>
        </Tooltip>
      )
  }
}

const mapToTableRow = (payments = [], sendOverdueEmail) =>
  payments.map((p) => {
    const today = new Date()

    if (isNil(p.dueDate)) return { ...p, overdueBy: '-', status: '-' }

    const diffInDays = differenceInDays(new Date(p.dueDate), today)

    return {
      ...p,
      overdueBy: getDaysOverdueByLabel(diffInDays, p.status),
      status: getPaymentStatusLabel(
        diffInDays,
        p.status,
        p.id,
        p.overDueEmailSent,
        sendOverdueEmail
      ),
    }
  })
export const Columns = () => {
  return React.useMemo(
    () => [
      {
        Header: 'Loan no.',
        accessor: path(['loan', 'loanNumber']),
        tableHeaderStyles: headerStyleBase({
          width: '19.2857142857%',
        }),
        tableCellStyles: cellStyleBase(s('font-normal', { width: '19.2857142857%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Recurrence',
        accessor: path(['loan', 'repaymentRecurrence']),
        tableHeaderStyles: headerStyleBase({
          width: '11.2857142857%',
        }),
        tableCellStyles: cellStyleBase(s('font-normal', { width: '11.2857142857%' })),
        Cell: ({ value }) => (
          <Tooltip title={value}>
            <TextOverflowEllipsis>{value}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Method',
        accessor: 'paymentMethod',
        tableHeaderStyles: headerStyleBase({
          width: '12.2857142857%',
        }),
        tableCellStyles: cellStyleBase(s('font-normal', { width: '12.2857142857%' })),
        // TODO: we are not collecting this in our system as its unsure when & how. It could come from the bank transactions that get imported
        Cell: () => (
          <Tooltip title="Direct debit">
            <TextOverflowEllipsis>{'Direct debit'}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        tableHeaderStyles: headerStyleBase({
          width: '14.2857142857%',
        }),
        tableCellStyles: cellStyleBase({ width: '14.2857142857%' }),
        Cell: ({ value }) => (
          <Tooltip title={`$${value ?? '-'}`}>
            <TextOverflowEllipsis>${value ?? '-'}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Due date',
        accessor: 'dueDate',
        tableHeaderStyles: headerStyleBase({
          width: '14.2857142857%',
        }),
        tableCellStyles: cellStyleBase({ width: '14.2857142857%' }),
        Cell: ({ value }) => (
          <Tooltip title={formatDateString(value)}>
            <TextOverflowEllipsis>{formatDateString(value)}</TextOverflowEllipsis>
          </Tooltip>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        tableHeaderStyles: headerStyleBase({
          width: '14.2857142857%',
        }),
        tableCellStyles: cellStyleBase({ width: '14.2857142857%' }),
      },
      {
        Header: 'Overdue',
        accessor: 'overdueBy',
        tableHeaderStyles: headerStyleBase({
          width: '14.2857142857%',
        }),
        tableCellStyles: cellStyleBase({ width: '14.2857142857%' }),
        Cell: ({ value }) => <TextOverflowEllipsis>{value}</TextOverflowEllipsis>,
      },
    ],
    []
  )
}

export const Rows = (payments = [], sendOverdueEmail) => {
  return React.useMemo(() => mapToTableRow(payments, sendOverdueEmail), [
    payments,
    sendOverdueEmail,
  ])
}
