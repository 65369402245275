/* eslint-disable complexity */
import React from 'react'
import { isNilOrEmpty } from '@neo/ramda-extra'
import { formatCurrency } from 'utils/formatter'
import remainingLoanTerm from 'utils/date/remaningLoanTerm'
import { routes } from 'routes'
import { useHistory, useParams } from 'react-router-dom'
import { REPAYMENT_TYPES, INTEREST_TYPES } from '@vega/constants'

import {
  ContentCard as ContentCardBase,
  SectionTitle as SectionTitleBase,
  DataDisplay,
} from '@vega/components/src/v2'
import { Row, Col, Container } from '@vega/styled'
import { styled, s } from '@vega/styled/v2'
import { round } from 'mathjs'
import {
  isPrimaryEntity,
  PrimaryPill,
  isGuarantorEntity,
  GuarantorPill,
  BorrowerPill,
  isBorrowerEntity,
} from '../../../loans/entitiesDetails/common'

const REPAYMENT_TYPE_DISPLAYED = {
  [REPAYMENT_TYPES.INTEREST_ONLY]: 'Interest Only',
  [REPAYMENT_TYPES.PRINCIPAL_AND_INTEREST]: 'Principal and Interest',
}

const INTEREST_TYPE_DISPLAYED = {
  [INTEREST_TYPES.FIXED]: 'Fixed',
  [INTEREST_TYPES.VARIABLE]: 'Variable',
}

const ContentCard = styled(ContentCardBase)(s('mb-9 p-5'))

const ViewButton = styled(SectionTitleBase)(s('text-sm'), {
  cursor: 'pointer',
  marginTop: 18,
})

const SectionTitle = styled(SectionTitleBase)(s('mb-4'))

const DataDisplayRow = styled.div(s('flex flex-row mt-0'))

const DataLabel = styled.div(s('text-grey-700 text-base'))

const DataValue = styled.div(s('text-grey-800 text-base font-semibold'))

const SubTitle = styled.div(s('py-6 text-grey-900 font-bold'))

const PurpleSubTitle = styled.div(s('py-4 text-primary ml-3 text-sm font-semibold'))

const formatRepaymentType = (loanType) => {
  if (loanType === REPAYMENT_TYPES.INTEREST_ONLY)
    return REPAYMENT_TYPE_DISPLAYED[REPAYMENT_TYPES.INTEREST_ONLY]
  if (loanType === REPAYMENT_TYPES.PRINCIPAL_AND_INTEREST)
    return REPAYMENT_TYPE_DISPLAYED[REPAYMENT_TYPES.PRINCIPAL_AND_INTEREST]

  return '-'
}

const formatInterestType = (type) => {
  if (type === INTEREST_TYPES.FIXED)
    return INTEREST_TYPE_DISPLAYED[INTEREST_TYPES.FIXED]
  if (type === INTEREST_TYPES.VARIABLE)
    return INTEREST_TYPE_DISPLAYED[INTEREST_TYPES.VARIABLE]

  return '-'
}

const formatMonthsToYears = (months) => {
  try {
    if (isNilOrEmpty(months)) return '-'

    return round(months / 12, 2)
  } catch (error) {
    return '-'
  }
}

const getLoanStructure = (numOfLoans = 0) => (numOfLoans > 1 ? 'Split' : 'Single')

export function LoanAccounts({ loanGroup = {}, loanAccountOrder = '' }) {
  const history = useHistory()
  const { id } = useParams()

  const loans = loanGroup?.loans || []

  const viewMortgageDetails = (id) =>
    history.push(`${routes.admin.loans.accountDetails.replace(':id', id)}`)

  return (
    <Container>
      <Row>
        <Col span={24}>
          <SectionTitle title={`Loan Account ${loanAccountOrder}`} />
          <ContentCard>
            <Row>
              <Col span={21}>
                <DataDisplayRow>
                  <DataLabel>Loan Group No:</DataLabel>
                  <DataValue>{loanGroup?.groupNumber || '-'}</DataValue>
                </DataDisplayRow>
              </Col>

              <Col span={24}>
                <SubTitle>Loan Group Info</SubTitle>
              </Col>
              <Col span={7}>
                <DataDisplay
                  label="Total Loan Amount"
                  value={formatCurrency(loanGroup?.totalLoanAmount)}
                />
              </Col>

              <Col span={7}>
                <DataDisplay
                  label="Loan Structure"
                  value={getLoanStructure(loans.length)}
                />
              </Col>

              {loans.map((loan, index) => (
                <Col span={24} key={index}>
                  <Row>
                    <Col span={24}>
                      <Row style={s('justify-between')}>
                        <PurpleSubTitle>LOAN {index + 1}</PurpleSubTitle>
                        <ViewButton
                          style={s('mr-8')}
                          title="View"
                          onClick={() => viewMortgageDetails(loans[index]._id)}
                        />
                      </Row>
                      {isPrimaryEntity(loan, id) && (
                        <PrimaryPill style={{ width: 'fit-content', margin: 0 }}>
                          PRIMARY
                        </PrimaryPill>
                      )}
                      {isGuarantorEntity(loan, id) && (
                        <GuarantorPill style={{ width: 'fit-content', margin: 0 }}>
                          GUARANTOR
                        </GuarantorPill>
                      )}
                      {isBorrowerEntity(loan, id) && (
                        <BorrowerPill style={{ width: 'fit-content', margin: 0 }}>
                          BORROWER
                        </BorrowerPill>
                      )}
                    </Col>

                    <Col span={7}>
                      <DataDisplay
                        label="Loan Amount"
                        value={formatCurrency(loan?.loanAmount)}
                      />
                    </Col>
                    <Col span={7}>
                      <DataDisplay
                        label="Loan Type"
                        value={formatRepaymentType(loan?.product?.repaymentType)}
                      />
                    </Col>
                    <Col span={7}>
                      <DataDisplay
                        label="Loan Term"
                        value={`${formatMonthsToYears(loan?.loanTermInMonths)} years`}
                      />
                    </Col>
                    <Col span={7}>
                      <DataDisplay
                        label="Remaining Loan Term"
                        value={`${remainingLoanTerm(
                          loan?.loanTermInMonths,
                          loanGroup.settlementDate
                        )}`}
                      />
                    </Col>

                    <Col span={7}>
                      <DataDisplay
                        label="Interest Type"
                        value={formatInterestType(loan?.product?.interestType)}
                      />
                    </Col>
                    <Col span={7}>
                      <DataDisplay
                        label="Interest Rate"
                        value={`${loan?.product?.interestRate || '-'}%`}
                      />
                    </Col>
                    {loan?.interestType === 'fixed' && (
                      <Col span={7}>
                        <DataDisplay
                          label="Interest Term"
                          value={`${loan?.interestTerm} months`}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              ))}
            </Row>
          </ContentCard>
        </Col>
      </Row>
    </Container>
  )
}
