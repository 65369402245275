import { isNilOrEmpty } from '@neo/ramda-extra'

export const formatCurrency = (value, digits = 0, placeholder = '$-') => {
  if (isNilOrEmpty(value) || typeof value !== 'number') return placeholder

  return new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    maximumFractionDigits: digits,
  }).format(value)
}

export const formatPercentage = (value, placeholder = '-%') => {
  try {
    if (isNilOrEmpty(value)) return placeholder

    return `${value}%`
  } catch (error) {
    return placeholder
  }
}

const throwIfInvalidHashFormat = (hashFormat) => {
  const notConsistWithOnlyHashAndSpace = /(?!(#|\s))./g.test(hashFormat)

  if (notConsistWithOnlyHashAndSpace) {
    throw new Error(
      'Invalid hash format passed into insertSeparatorsToString: It should be a string consisted with hashes and spaces'
    )
  }
}

// eslint-disable-next-line complexity
export const insertSeparatorsToString = (
  value,
  separator = ' ',
  hashFormat = '#### ####',
  placeholder = '-'
) => {
  if (isNilOrEmpty(value)) return placeholder

  throwIfInvalidHashFormat(hashFormat)

  let formattedString = ''
  const hashFormatArr = hashFormat.toString().split('')

  let inputValuePointer = 0
  const stopForLoopAtIdx = value.length
  for (
    let hashValuePointer = 0;
    hashValuePointer < hashFormatArr.length;
    hashValuePointer += 1
  ) {
    if (inputValuePointer === stopForLoopAtIdx) break

    if (hashFormatArr[hashValuePointer] === '#') {
      formattedString += value.charAt(inputValuePointer)
      inputValuePointer += 1
      // eslint-disable-next-line no-continue
      continue
    }

    formattedString += `${separator}${value.charAt(inputValuePointer)}`
    inputValuePointer += 1
    hashValuePointer += 1
  }

  return formattedString
}
export const toCamelCase = (string = '-') => {
  return string
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

export const formatMonthsToYears = (months) => {
  try {
    if (isNilOrEmpty(months)) return '-'

    const totalYears = Math.floor(months / 12)
    const totalMonths = months % 12

    if (totalYears === 0) return `${totalMonths} months`
    if (totalMonths === 0) return `${totalYears} years`
    return `${totalYears} years ${totalMonths} months`
  } catch (error) {
    return '-'
  }
}
