import { prop } from '@neo/ramda-extra'
import { createSelector } from '@reduxjs/toolkit'
import { transactionsAdapter } from './module'

import { createAsyncThunkSelectors } from 'utils/createAsyncThunkSelectors'

const selectTransactions = prop('transactions')

export const selectIsFetchingTransactions = createSelector(
  selectTransactions,
  prop('isFetchingTransactions')
)

export const {
  selectIsPending: selectIsFetchTransactionsPending,
} = createAsyncThunkSelectors('transactions/fetchTransactions')

export const {
  selectEntities: selectTransactionEntities,
  selectAll: selectAllTransactions,
  selectById: selectTransactionById,
} = transactionsAdapter.getSelectors(prop('transactions'))
